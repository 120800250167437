import styles from "./Domen.module.scss";
import CheckboxMUI from "../../Components/Atom/CheckboxMUI";
import { useState, useEffect } from "react";
import Instruction from "../../Components/Molecule/Instruction";
import Text from "../../Components/Atom/Text";
import PwaLayout from "../../common/Layout/PwaLayout";
import { addedDomain } from "../../features/createdPwa/createdPwaSlice.ts";
import { useAppDispatch } from "../../lib/redux/hooks.ts";

const Domain = () => {
  const [isDomain, setIsDomain] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addedDomain(""));
  }, []);

  return (
    <PwaLayout>
      <div className={styles.wrapper}>
        <Text
          className={isDomain ? styles.isTitle : styles.title}
          text="YouWillBeProvided"
        />
        <div className={styles.domain}>
          <CheckboxMUI
            label="IHaveMyOwnDomain"
            setState={setIsDomain}
            state={isDomain}
          />
        </div>
        <div className={!isDomain ? styles.isInstruction : styles.instruction}>
          <Instruction />
        </div>
      </div>
    </PwaLayout>
  );
};

export default Domain;
