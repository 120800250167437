import { TSelectData } from "./types.ts";

export const appNameData: TSelectData[] = [
  {
    id: 1,
    name: "Book of Sands",
  },
  {
    id: 2,
    name: "Best Slots",
  },
  {
    id: 3,
    name: "Aviator Slot",
  },
  {
    id: 4,
    name: "Book of Ra",
  },
  {
    id: 5,
    name: "Leo Slots",
  },
  {
    id: 6,
    name: "PariMatch",
  },
  {
    id: 7,
    name: "Big Win Casino",
  },
  {
    id: 8,
    name: "Pin-Up",
  },
  {
    id: 9,
    name: "Leo Vegas",
  },
  {
    id: 11,
    name: "Vulkan",
  },
  {
    id: 12,
    name: "Nine Casino",
  },
];

export const authorData: TSelectData[] = [
  {
    id: 1,
    name: "Play dev",
  },
  {
    id: 2,
    name: "Aviator Dev",
  },
  {
    id: 3,
    name: "Pin-Up Dev",
  },
  {
    id: 4,
    name: "Vulkan Dev",
  },
  {
    id: 5,
    name: "Nine Dev",
  },
  {
    id: 6,
    name: "Google Dev",
  },
];

export const categoryData: TSelectData[] = [
  {
    id: 1,
    name: "Casino",
  },
  {
    id: 2,
    name: "Finance",
  },
  {
    id: 3,
    name: "Dating",
  },
  {
    id: 4,
    name: "Tools",
  },
  {
    id: 5,
    name: "Sport",
  },
];

export const ageData: TSelectData[] = [
  {
    id: 1,
    name: 18,
  },
  {
    id: 2,
    name: 16,
  },
  {
    id: 3,
    name: 14,
  },
  {
    id: 4,
    name: 12,
  },
];

export const sizeData: TSelectData[] = [
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "3",
  },
  {
    id: 3,
    name: "6",
  },
  {
    id: 4,
    name: "9",
  },
  {
    id: 5,
    name: "12",
  },
  {
    id: 6,
    name: "15",
  },
];

export const quantityData: TSelectData[] = [
  {
    id: 1,
    name: "50000",
  },
  {
    id: 2,
    name: "100000",
  },
  {
    id: 3,
    name: "10000",
  },
  {
    id: 4,
    name: "1000",
  },
];

export const descriptionData: TSelectData[] = [
  {
    id: 1,
    name: "Казино",
    value:
      "НОВЫЕ СЛОТЫ 2021\n" +
      "\n" +
      "Добро пожаловать в НОВЫЕ СЛОТЫ 2021!  казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!\n" +
      "\n" +
      "★ Потрясающий приветственный  казино бонус 700.000 Free Spin\n" +
      "★ Новые слоты без интернета бесплатно каждую неделю!\n" +
      "★  казино соревнования с другими игроками онлайн!\n" +
      "★ Разнообразные игровые автоматы бесплатно без интернета и каждый имеет свою особенность и уникальную бонусную игру!\n" +
      "★ Большие награды для тех, кто заходит в игровые автоматы онлайн каждый день!\n" +
      "★ Захватывающие игровые  казино события не дадут заскучать никому!\n" +
      "★ Заходите в казино симулятор каждые 2 часа чтобы прокрутить колесо удачи бесплатно!\n" +
      "★ А некоторые слот машины имеют такое количество бесплатных вращений, что, кажется, ваши  казино барабаны будут вращаться бесконечно!\n" +
      "★ Классические слоты без интернета бесплатно и современные игровые автоматы онлайн собраны в одной казино симулятор игре!\n" +
      "Откройте для себя красочные игровые автоматы бесплатно без интернета. Великолепная графика, яркие слот машины и невероятные  казино бонусы ждут вас. Играйте в слот машины в любое время и в любом месте. Теперь получать удовольствие от казино симулятор игры можно даже без подключения к интернету.\n" +
      "Установите бесплатные игровые автоматы и получите уникальную возможность сразиться с эпическими  казино боссами в нашем казино симуляторе. Одержите победу и получите дополнительную награду. Для каждой слот машины доступны казино квесты, выполнив которые вы получите коллекции предметов, с помощью этих предметов можно улучшить бесплатные игровые автоматы и бонус слот машины. \n" +
      "Играйте в игровые автоматы онлайн, участвуйте и побеждайте в  казино соревнованиях с другими игроками. Самые удачливые получают ещё больше наград в слотах. Скачайте наш казино симулятор прямо сейчас и возглавьте рейтинг в  казино! Играя в любые слоты без интернета бесплатно, вы получаете шанс выиграть огромный джекпот. \n" +
      "НОВЫЕ СЛОТЫ 2021 не является азартной игрой. В ней нет выплат реальных денег или любых других материальных наград. Эта игра только симулирует работу слот машин и не гарантирует успех в реальном казино или реальных слот машинах.  ",
  },
  {
    id: 2,
    name: "Спорт",
    value:
      "НОВЫЕ СЛОТЫ 2021\n" +
      "\n" +
      "Добро пожаловать в НОВЫЕ СЛОТЫ 2021!  казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!\n" +
      "\n" +
      "★ Потрясающий приветственный  казино бонус 700.000 Free Spin\n" +
      "★ Новые слоты без интернета бесплатно каждую неделю!\n" +
      "★  казино соревнования с другими игроками онлайн!\n" +
      "★ Разнообразные игровые автоматы бесплатно без интернета и каждый имеет свою особенность и уникальную бонусную игру!\n" +
      "★ Большие награды для тех, кто заходит в игровые автоматы онлайн каждый день!\n" +
      "★ Захватывающие игровые  казино события не дадут заскучать никому!\n" +
      "★ Заходите в казино симулятор каждые 2 часа чтобы прокрутить колесо удачи бесплатно!\n" +
      "★ А некоторые слот машины имеют такое количество бесплатных вращений, что, кажется, ваши  казино барабаны будут вращаться бесконечно!\n" +
      "★ Классические слоты без интернета бесплатно и современные игровые автоматы онлайн собраны в одной казино симулятор игре!\n" +
      "Откройте для себя красочные игровые автоматы бесплатно без интернета. Великолепная графика, яркие слот машины и невероятные  казино бонусы ждут вас. Играйте в слот машины в любое время и в любом месте. Теперь получать удовольствие от казино симулятор игры можно даже без подключения к интернету.\n" +
      "Установите бесплатные игровые автоматы и получите уникальную возможность сразиться с эпическими  казино боссами в нашем казино симуляторе. Одержите победу и получите дополнительную награду. Для каждой слот машины доступны казино квесты, выполнив которые вы получите коллекции предметов, с помощью этих предметов можно улучшить бесплатные игровые автоматы и бонус слот машины. \n" +
      "Играйте в игровые автоматы онлайн, участвуйте и побеждайте в  казино соревнованиях с другими игроками. Самые удачливые получают ещё больше наград в слотах. Скачайте наш казино симулятор прямо сейчас и возглавьте рейтинг в  казино! Играя в любые слоты без интернета бесплатно, вы получаете шанс выиграть огромный джекпот. \n" +
      "НОВЫЕ СЛОТЫ 2021 не является азартной игрой. В ней нет выплат реальных денег или любых других материальных наград. Эта игра только симулирует работу слот машин и не гарантирует успех в реальном казино или реальных слот машинах.  ",
  },
  {
    id: 3,
    name: "Знакомства",
    value:
      "НОВЫЕ СЛОТЫ 2021\n" +
      "\n" +
      "Добро пожаловать в НОВЫЕ СЛОТЫ 2021!  казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!\n" +
      "\n" +
      "★ Потрясающий приветственный  казино бонус 700.000 Free Spin\n" +
      "★ Новые слоты без интернета бесплатно каждую неделю!\n" +
      "★  казино соревнования с другими игроками онлайн!\n" +
      "★ Разнообразные игровые автоматы бесплатно без интернета и каждый имеет свою особенность и уникальную бонусную игру!\n" +
      "★ Большие награды для тех, кто заходит в игровые автоматы онлайн каждый день!\n" +
      "★ Захватывающие игровые  казино события не дадут заскучать никому!\n" +
      "★ Заходите в казино симулятор каждые 2 часа чтобы прокрутить колесо удачи бесплатно!\n" +
      "★ А некоторые слот машины имеют такое количество бесплатных вращений, что, кажется, ваши  казино барабаны будут вращаться бесконечно!\n" +
      "★ Классические слоты без интернета бесплатно и современные игровые автоматы онлайн собраны в одной казино симулятор игре!\n" +
      "Откройте для себя красочные игровые автоматы бесплатно без интернета. Великолепная графика, яркие слот машины и невероятные  казино бонусы ждут вас. Играйте в слот машины в любое время и в любом месте. Теперь получать удовольствие от казино симулятор игры можно даже без подключения к интернету.\n" +
      "Установите бесплатные игровые автоматы и получите уникальную возможность сразиться с эпическими  казино боссами в нашем казино симуляторе. Одержите победу и получите дополнительную награду. Для каждой слот машины доступны казино квесты, выполнив которые вы получите коллекции предметов, с помощью этих предметов можно улучшить бесплатные игровые автоматы и бонус слот машины. \n" +
      "Играйте в игровые автоматы онлайн, участвуйте и побеждайте в  казино соревнованиях с другими игроками. Самые удачливые получают ещё больше наград в слотах. Скачайте наш казино симулятор прямо сейчас и возглавьте рейтинг в  казино! Играя в любые слоты без интернета бесплатно, вы получаете шанс выиграть огромный джекпот. \n" +
      "НОВЫЕ СЛОТЫ 2021 не является азартной игрой. В ней нет выплат реальных денег или любых других материальных наград. Эта игра только симулирует работу слот машин и не гарантирует успех в реальном казино или реальных слот машинах.  ",
  },
  {
    id: 4,
    name: "Финансы ",
    value:
      "НОВЫЕ СЛОТЫ 2021\n" +
      "\n" +
      "Добро пожаловать в НОВЫЕ СЛОТЫ 2021!  казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!\n" +
      "\n" +
      "★ Потрясающий приветственный  казино бонус 700.000 Free Spin\n" +
      "★ Новые слоты без интернета бесплатно каждую неделю!\n" +
      "★  казино соревнования с другими игроками онлайн!\n" +
      "★ Разнообразные игровые автоматы бесплатно без интернета и каждый имеет свою особенность и уникальную бонусную игру!\n" +
      "★ Большие награды для тех, кто заходит в игровые автоматы онлайн каждый день!\n" +
      "★ Захватывающие игровые  казино события не дадут заскучать никому!\n" +
      "★ Заходите в казино симулятор каждые 2 часа чтобы прокрутить колесо удачи бесплатно!\n" +
      "★ А некоторые слот машины имеют такое количество бесплатных вращений, что, кажется, ваши  казино барабаны будут вращаться бесконечно!\n" +
      "★ Классические слоты без интернета бесплатно и современные игровые автоматы онлайн собраны в одной казино симулятор игре!\n" +
      "Откройте для себя красочные игровые автоматы бесплатно без интернета. Великолепная графика, яркие слот машины и невероятные  казино бонусы ждут вас. Играйте в слот машины в любое время и в любом месте. Теперь получать удовольствие от казино симулятор игры можно даже без подключения к интернету.\n" +
      "Установите бесплатные игровые автоматы и получите уникальную возможность сразиться с эпическими  казино боссами в нашем казино симуляторе. Одержите победу и получите дополнительную награду. Для каждой слот машины доступны казино квесты, выполнив которые вы получите коллекции предметов, с помощью этих предметов можно улучшить бесплатные игровые автоматы и бонус слот машины. \n" +
      "Играйте в игровые автоматы онлайн, участвуйте и побеждайте в  казино соревнованиях с другими игроками. Самые удачливые получают ещё больше наград в слотах. Скачайте наш казино симулятор прямо сейчас и возглавьте рейтинг в  казино! Играя в любые слоты без интернета бесплатно, вы получаете шанс выиграть огромный джекпот. \n" +
      "НОВЫЕ СЛОТЫ 2021 не является азартной игрой. В ней нет выплат реальных денег или любых других материальных наград. Эта игра только симулирует работу слот машин и не гарантирует успех в реальном казино или реальных слот машинах.  ",
  },
  {
    id: 5,
    name: "Mobile tools",
    value:
      "НОВЫЕ СЛОТЫ 2021\n" +
      "\n" +
      "Добро пожаловать в НОВЫЕ СЛОТЫ 2021!  казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!\n" +
      "\n" +
      "★ Потрясающий приветственный  казино бонус 700.000 Free Spin\n" +
      "★ Новые слоты без интернета бесплатно каждую неделю!\n" +
      "★  казино соревнования с другими игроками онлайн!\n" +
      "★ Разнообразные игровые автоматы бесплатно без интернета и каждый имеет свою особенность и уникальную бонусную игру!\n" +
      "★ Большие награды для тех, кто заходит в игровые автоматы онлайн каждый день!\n" +
      "★ Захватывающие игровые  казино события не дадут заскучать никому!\n" +
      "★ Заходите в казино симулятор каждые 2 часа чтобы прокрутить колесо удачи бесплатно!\n" +
      "★ А некоторые слот машины имеют такое количество бесплатных вращений, что, кажется, ваши  казино барабаны будут вращаться бесконечно!\n" +
      "★ Классические слоты без интернета бесплатно и современные игровые автоматы онлайн собраны в одной казино симулятор игре!\n" +
      "Откройте для себя красочные игровые автоматы бесплатно без интернета. Великолепная графика, яркие слот машины и невероятные  казино бонусы ждут вас. Играйте в слот машины в любое время и в любом месте. Теперь получать удовольствие от казино симулятор игры можно даже без подключения к интернету.\n" +
      "Установите бесплатные игровые автоматы и получите уникальную возможность сразиться с эпическими  казино боссами в нашем казино симуляторе. Одержите победу и получите дополнительную награду. Для каждой слот машины доступны казино квесты, выполнив которые вы получите коллекции предметов, с помощью этих предметов можно улучшить бесплатные игровые автоматы и бонус слот машины. \n" +
      "Играйте в игровые автоматы онлайн, участвуйте и побеждайте в  казино соревнованиях с другими игроками. Самые удачливые получают ещё больше наград в слотах. Скачайте наш казино симулятор прямо сейчас и возглавьте рейтинг в  казино! Играя в любые слоты без интернета бесплатно, вы получаете шанс выиграть огромный джекпот. \n" +
      "НОВЫЕ СЛОТЫ 2021 не является азартной игрой. В ней нет выплат реальных денег или любых других материальных наград. Эта игра только симулирует работу слот машин и не гарантирует успех в реальном казино или реальных слот машинах.  ",
  },
];

export const updatesData: TSelectData[] = [
  {
    id: 1,
    name: "Новая игра Book of Ra",
  },
  {
    id: 2,
    name: "Новая игра Book of Ra",
  },
  {
    id: 3,
    name: "Новая игра Time Of Sands",
  },
  {
    id: 4,
    name: "Бонус 300 frees pins",
  },
  {
    id: 5,
    name: "Бонус 5000 Рублей ",
  },
  {
    id: 6,
    name: "Бонус 300 Евро",
  },
];
