import { FC } from "react";
import { TLayout } from "../types.ts";
import AuthHeader from "../../../Components/Molecule/AuthHeader";
import styles from "./styles.module.scss";

const AuthLayout: FC<TLayout> = ({ children }) => {
  return (
    <section className={styles.wrapper}>
      <AuthHeader />
      <div className={styles.container}>{children}</div>
    </section>
  );
};

export default AuthLayout;
