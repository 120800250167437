import styles from "./TabMenu.module.scss";
import type { FC } from "react";
import { TTabMenuItemProps } from "./types.ts";
import Text from "../Text";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

const TabMenuItem: FC<TTabMenuItemProps> = ({
  routh,
  title,
  category,
  id,
  active,
  disabled,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    if (!disabled) {
      navigate({ pathname: routh, search: location.search });
    }
  };
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.isActive]: active },
        { [styles.isDisabled]: disabled }
      )}
      onClick={handleNavigate}
      role="button"
    >
      <div className={styles.number}>{id}</div>
      <div className={styles.block}>
        <Text className={styles.category} text={title} />
        <Text className={styles.item} text={category} />
      </div>
    </div>
  );
};

export default TabMenuItem;
