import styles from "./styles.module.scss";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import classNames from "classnames";

const AdditionalInfo = () => {
  const phoneInformation = useAppSelector((state) => state.getPwa?.data?.pwa);
  return (
    <div className={styles.wrapper}>
      <p
        className={classNames(
          styles.title,
          phoneInformation?.color_schema === "dark" && styles.dark
        )}
      >
        Additional information
      </p>
      <div className={styles.container}>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Updated
          </p>
          <p className={styles.date}>
            {phoneInformation && phoneInformation.app_updated_at.slice(0, 10)}
          </p>
        </div>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Size
          </p>
          <p className={styles.date}>{`${
            phoneInformation && phoneInformation.app_size
          } Mb`}</p>
        </div>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Installs
          </p>
          <p className={styles.date}>{`${
            phoneInformation && phoneInformation.installs_quantity
          }+`}</p>
        </div>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Current version
          </p>
          <p className={styles.date}>
            {phoneInformation && phoneInformation.app_version}
          </p>
        </div>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Age
          </p>
          <p className={styles.date}>18+</p>
        </div>
        <div className={styles.block}>
          <p
            className={classNames(
              styles.info,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            Developer
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
