import styles from "./DatePikerMUI.module.scss";
import { FC, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TDatePikerProps } from "./types.ts";
import { styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import Text from "../Text";

const CustomDatePiker = styled(DatePicker)`
  & .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1.5px solid rgba(117, 117, 117, 0.28);
  }
`;

const DatePikerMUI: FC<TDatePikerProps> = ({
  label,
  id,
  onChange,
  name,
  value,
  setValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<Dayjs | null>(null);
  const handleChange = (date: Dayjs | null) => {
    setValue &&
      setValue((prevFormData) => {
        return { ...prevFormData, [name]: value };
      });
    onChange && onChange(date);
    if (id && !value) {
      setSelectedValue(date);
      onChange && onChange(date!.format("YYYY-MM-DD"), name, id);
    }
  };

  return (
    <div className={styles.wrapper}>
      {label && <Text className={styles.text} text={label} tagName="p" />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDatePiker
          value={dayjs(value ? value : selectedValue)}
          onChange={handleChange}
          defaultValue={dayjs("2022-04-17")}
          format="YYYY-MM-DD"
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePikerMUI;
