import { Button } from "@mui/material";
import styles from "./styles.module.scss";
import { FC } from "react";
import { TUploadFileProps } from "./types.ts";
import Text from "../Text";

const UploadFile: FC<TUploadFileProps> = ({
  label,
  error,
  register,
  text,
  onChange,
  id,
  name,
}) => {
  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (onChange && id) {
      onChange(file as FormData, name, id);
    }
  };
  return (
    <div className={styles.wrapper}>
      {label && <Text className={styles.text} text={label} tagName="p" />}
      <div>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id={name}
          type="file"
          name={name}
          onChange={handleFileUpload}
          {...(register ? register(name) : null)}
        />
        <label htmlFor={name}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#105b0c", color: "#ffffff" }}
            component="span"
          >
            {text ? text : "Choose Image"}
          </Button>
        </label>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default UploadFile;
