import { FC, useEffect } from "react";
import styles from "./devices.module.scss";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import Text from "../../Components/Atom/Text/index.tsx";
import PwaSelect from "../../Components/Atom/PwaSelect/index.tsx";
import { ownPwaThunk } from "../../features/ownPwa/ownPwaServices.ts";
import DevicesTable from "../../Components/Molecule/DevicesTable/index.tsx";

const Devices: FC = () => {
  const rowsData: any = useAppSelector((state) => state.ownPwa.data);
  const { data } = useAppSelector((state) => state.pwaDevices);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(ownPwaThunk());
  }, []);

  useEffect(() => {
    console.log(data);
  },[data])

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <Text className={styles.text} text="PWA Devices" tagName="p" />
        <div className={styles.select}>
          <PwaSelect data={rowsData?.pwas} />
        </div>
      </div>
      <Text className={styles.text} text="Tokens" tagName="p" />
      <div className={styles.detailed}>
            <DevicesTable/>
        </div>
    </div>
  );
};
export default Devices;
