import Input from "../../Atom/Input";
import Button from "../../Atom/Button";
import styles from "./styles.module.scss";
import Text from "../../Atom/Text";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import confirmPasswordSchemas from "../../../common/Schemas/confirmPasswordSchemas.ts";
import { TConfirmData } from "./types.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import {
  checkPassword,
  confirmPassword,
} from "../../../features/resetPassword/resetPasswordServices.ts";
import Loader from "../../Atom/Loader";
import ErrorPage from "../../../pages/errorPage";

const ConfirmPassword = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.resetPassword.checkPasswordData.loading
  );
  const status = useAppSelector(
    (state) => state.resetPassword.checkPasswordData.data?.status
  );
  const statusConfirm = useAppSelector(
    (state) => state.resetPassword.resetPasswordData.data?.status
  );
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const userPayload = {
    email: params.get("email"),
    ref_code: params.get("ref_code"),
  };
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkPassword(userPayload));
    if (statusConfirm == "success") {
      navigate("/login");
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TConfirmData>({
    mode: "onChange",
    resolver: yupResolver(confirmPasswordSchemas),
  });

  const [passwordValues, setPasswordValues] = useState<number | string>("");
  const [isError, setIsError] = useState(false);

  const onSubmit: SubmitHandler<TConfirmData> = (data) => {
    if (data.password !== passwordValues) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    dispatch(confirmPassword({ ...data, ...userPayload }));
  };

  const handleConfirmPassword = (value: string | number) => {
    if (value) {
      setPasswordValues(value);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {status === "success" ? (
            <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
              <Text className={styles.title} text="pwa" tagName="p" />
              <div className={styles.lists}>
                <Input
                  label="password"
                  name="password"
                  error={errors.password?.message}
                  type="password"
                  register={register}
                />
                <Input
                  label="confirmPassword"
                  name="confirm"
                  error={isError ? "confirm" : undefined}
                  type="password"
                  onChange={handleConfirmPassword}
                />
              </div>
              <div className={styles.bottom}>
                <Button type="submit">Reset</Button>
              </div>
            </form>
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </>
  );
};

export default ConfirmPassword;
