import styles from "./Gallery.module.scss";
import { FC } from "react";
import { TGalleryProps } from "./types.ts";

const Gallery: FC<TGalleryProps> = ({ data }) => {
  const imageUrl = process.env.REACT_APP_CLIENT_URL;

  return (
    <div className={styles.wrapper}>
      {data &&
        data.map((element, index) => {
          if (index >= 1) {
            return (
              <div className={styles.image} key={element.id}>
                <img
                  className={styles.img}
                  src={`${imageUrl}${element.url}`}
                  alt={element.url}
                  width="100%"
                  height="100%"
                />
              </div>
            );
          }
          return null;
        })}
    </div>
  );
};

export default Gallery;
