import styles from "./styles.module.scss";
import Input from "../../Atom/Input";
import Text from "../../Atom/Text";
import DatePikerMUI from "../../Atom/DatePikerMUI";
import { FC } from "react";
import { TAnswerProps } from "./types.ts";

const Answer: FC<TAnswerProps> = ({ onChange, id }) => {
  return (
    <div className={styles.wrapper}>
      <Text className={styles.title} text="Answer" tagName="p" />
      <div className={styles.container}>
        <Input
          id={id}
          name="full_name"
          onChange={onChange}
          label="Author"
          variant="filled"
        />
        <div className={styles.date}>
          <DatePikerMUI
            id={id}
            label="Date"
            onChange={onChange}
            name="published_at"
          />
        </div>
      </div>
      <div className={styles.input}>
        <Input
          name="text"
          id={id}
          onChange={onChange}
          variant="filled"
          label="Answer"
        />
      </div>
    </div>
  );
};

export default Answer;
