import { TTextProps } from "./types.ts";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const Text: FC<TTextProps> = ({ text, tagName = "p", className, children }) => {
  const Tag = tagName;
  const { t } = useTranslation();
  return (
    <>
      {text && <Tag className={className}>{t(text)}</Tag>}
      {children && <Tag>{t(children as string)}</Tag>}
    </>
  );
};
export default Text;
