import { useEffect, Dispatch } from 'react';
import { useAppDispatch, useAppSelector } from "./lib/redux/hooks.ts";
import { messaging } from "./firebase.ts";
import { getToken, Messaging } from "firebase/messaging";
import { addPushTokenThunk } from './features/addPushToken/addPushTokenServices.ts';

const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;
  
const Downloadable = () => {
  const storage_url = useAppSelector((state) => state.apk.data?.url);
  const dispatch = useAppDispatch();
  const url = localStorage.getItem("_pwa_app_url");

  const requestPermission = async () => {
    //requesting permission using Notification API
    if (isSupported()) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = localStorage.getItem("push_token");
        if (messaging && !token) {
          const token = await getToken(messaging as unknown as Messaging, {
            vapidKey: 'BFYRZQbVtF4FKYuSj4o5QKlt3vlHgAFFJhuotPh3oDHiSzsqBRcRd72yUGgIwoOzkCxMYRoCLfemxqmpwAMVoP0',// process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });
          await dispatch(addPushTokenThunk(token)).unwrap();
          localStorage.setItem("push_token", token);
          //We can send token to server
          console.log("Token generated : ", token);
        }
      } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
      }
    }
  };

  useEffect(() => {
    (async () => {
      await requestPermission();
      if (storage_url) {
        window.location.href = storage_url;
      }
    })();
   
  }, [url, storage_url]);

  return null;
};

export default Downloadable;
