import { createSlice } from "@reduxjs/toolkit";
import { ownPwaThunk } from "./ownPwaServices.ts";
import { IInitialStateDataOwn } from "./types.ts";

const initialState: IInitialStateDataOwn = {
  data: null,
  loading: false,
  error: null,
};

export const ownPwaSlice = createSlice({
  name: "ownPwa",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ownPwaThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(ownPwaThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(ownPwaThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
