import styles from "./Application.module.scss";
import AutoSelect from "../../Components/Molecule/AutoSelect";
import Input from "../../Components/Atom/Input";
import SelectMUI from "../../Components/Atom/SelectMUI";
import DatePikerMUI from "../../Components/Atom/DatePikerMUI";
import RangeSliderMui from "../../Components/Atom/RangeSliderMui";
import { Dayjs } from "dayjs";
import { debounce } from "lodash";
import {
  ageData,
  authorData,
  categoryData,
  descriptionData,
  quantityData,
  sizeData,
} from "../../Components/Molecule/AutoSelect/utils.ts";
import {
  avatarData,
  buttonData,
  schemasData,
} from "../../Components/Atom/SelectMUI/utils.ts";
import Text from "../../Components/Atom/Text";
import PwaLayout from "../../common/Layout/PwaLayout";
import { useEffect, useState } from "react";
import { updateField } from "../../features/updatePwa/updatePwaSlice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TUpdatePWA } from "./types.ts";
import { updatePwaThunk } from "../../features/updatePwa/updatePwaServices.ts";
import { getPwaThunk } from "../../features/getPwa/getPwaServices.ts";
import { useTranslation } from "react-i18next";

const Application = () => {
  const dispatch = useAppDispatch();
  const pwaId =
    localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));
  const pwaUpdateData = useAppSelector(
    (state) => state.getPwa.data?.pwa?.template_id
  );
  const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<TUpdatePWA>({
    age: "",
    author: "",
    category: "",
    app_desc: "",
    name: "",
    app_size: "",
    text: "",
    installs_quantity: "",
    app_version: "",
    app_updated_at: "",
    launch_btn: "play",
  });

  useEffect(() => {
    if ((pwaId || searchParams) && pwaUpdateData) {
      dispatch(
        updateField({
          id: pwaId || searchQueryPwaId,
          field: "template_id",
          value: pwaUpdateData,
        })
      );
    }
  }, [pwaId]);

  const debouncedHandleBlur = debounce((name, value) => {
    if (pwaId) {
      dispatch(
        updatePwaThunk({
          id: pwaId,
          field: name,
          value: String(formData[name as keyof typeof formData]).trim()
            ? formData[name as keyof typeof formData]
            : value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        updatePwaThunk({
          id: searchQueryPwaId,
          field: name,
          value: String(formData[name as keyof typeof formData]).trim()
            ? formData[name as keyof typeof formData]
            : value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(searchQueryPwaId)));
    }
  }, 1500);

  const handleSelect = (value: string | number, name: string) => {
    if (pwaId) {
      dispatch(
        updatePwaThunk({
          id: pwaId,
          field: name,
          value: value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        updatePwaThunk({
          id: searchQueryPwaId,
          field: name,
          value: value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(searchQueryPwaId)));
    }
  };

  const handleRangeSelect = debounce((value, name) => {
    if (pwaId) {
      dispatch(
        updatePwaThunk({
          id: pwaId,
          field: name,
          value: value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        updatePwaThunk({
          id: searchQueryPwaId,
          field: name,
          value: value,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(searchQueryPwaId)));
    }
  }, 700);

  const handleBlur = (name: string, value: string | number | Dayjs | null) => {
    debouncedHandleBlur(name, value);
  };

  const { t } = useTranslation();

  return (
    <PwaLayout>
      <div className={styles.wrapper}>
        <Text className={styles.text} text="GeneralSettings" tagName="p" />
        <form className={styles.forms}>
          <div className={styles.appName}>
            <Input
              variant="filled"
              value={pwaData?.name || ""}
              placeholder="My App"
              label="name"
              onChange={(value) => handleBlur("name", value)}
            />
          </div>
          <div className={styles.container}>
            <div>
              <AutoSelect
                value={formData.author}
                defaultValue={pwaData?.author || ""}
                menuData={authorData}
                placeholder="Автор"
                label="Author"
                onChange={(value) => handleBlur("author", value)}
              />
            </div>
            <div>
              <AutoSelect
                value={formData.category}
                menuData={categoryData}
                defaultValue={pwaData?.category || ""}
                placeholder="Категория"
                label="Category"
                onChange={(value) => handleBlur("category", value)}
              />
            </div>
            <div>
              <AutoSelect
                placeholder="Возраст"
                label="age"
                defaultValue={pwaData?.age || ""}
                value={formData.age}
                menuData={ageData}
                onChange={(value) => handleBlur("age", value)}
              />
            </div>
            <div>
              <AutoSelect
                defaultValue={pwaData?.app_size || ""}
                value={formData.app_size}
                onChange={(value) => handleBlur("app_size", value)}
                menuData={sizeData}
                placeholder="Размер"
                label="size"
              />
            </div>
            <div>
              <Input
                value={formData.app_version}
                onChange={(value) => handleBlur("app_version", value)}
                variant="filled"
                label="CurrentVersion"
                defaultValue="1.0"
              />
            </div>
            <div>
              <AutoSelect
                value={formData.installs_quantity}
                defaultValue={pwaData?.installs_quantity || ""}
                menuData={quantityData}
                placeholder="Количество установок"
                label="TotalInstalls"
                onChange={(value) => handleBlur("installs_quantity", value)}
              />
            </div>
            <div>
              <DatePikerMUI
                name="app_updated_at"
                setValue={setFormData}
                value={formData.app_updated_at}
                label="LastUpdateDate"
                onChange={(value) => handleBlur("app_updated_at", value)}
              />
            </div>
            <div>
              <SelectMUI
                name="launch_btn"
                onChange={handleSelect}
                selectMenu={buttonData}
                label="ApplicationLaunchButton"
              />
            </div>
            <div>
              <SelectMUI
                onChange={handleSelect}
                name="color_schema"
                selectMenu={schemasData}
                label="ColorScheme"
              />
            </div>
          </div>
          <div>
            <AutoSelect
              value={formData.app_desc}
              defaultValue={pwaData?.app_desc || ""}
              onChange={(value) => handleBlur("app_desc", value)}
              menuData={descriptionData}
              label="description"
              variant="textarea"
            />
          </div>
          <Text className={styles.rating} text="Rating" tagName="p" />
          <div className={styles.container}>
            <div>
              <RangeSliderMui
                defaultValue={pwaData?.five_rating_quantity}
                name="five_rating_quantity"
                onChange={handleRangeSelect}
                label="NumberOfRatings5"
              />
            </div>
            <div>
              <RangeSliderMui
                defaultValue={pwaData?.four_rating_quantity}
                onChange={handleRangeSelect}
                name="four_rating_quantity"
                label="NumberOfRatings4"
              />
            </div>
            <div>
              <RangeSliderMui
                defaultValue={pwaData?.three_rating_quantity}
                onChange={handleRangeSelect}
                name="three_rating_quantity"
                label="NumberOfRatings3"
              />
            </div>
            <div>
              <RangeSliderMui
                defaultValue={pwaData?.two_rating_quantity}
                onChange={handleRangeSelect}
                name="two_rating_quantity"
                label="NumberOfRatings2"
              />
            </div>
            <div>
              <RangeSliderMui
                defaultValue={pwaData?.one_rating_quantity}
                onChange={handleRangeSelect}
                name="one_rating_quantity"
                label="NumberOfRatings1"
              />
            </div>
          </div>
          <Text className={styles.rating} tagName="p" text="Comments" />
          <div className={styles.textBlock}>
            <p className={styles.comment}>{t("SelectTheNumber")} </p>
            <p
              onClick={() => {
                navigate({ pathname: "/comment", search: location.search });
              }}
              className={styles.write}
            >
              {t("writeThemByHand")}{" "}
            </p>
          </div>

          <div className={styles.rangeSlider}>
            <RangeSliderMui
              defaultValue={pwaData?.comments_quantity}
              max={25}
              onChange={handleRangeSelect}
              name="comments_quantity"
            />
          </div>
          <SelectMUI
            name="launch_bt"
            selectMenu={avatarData}
            label="AvatarType"
          />
        </form>
      </div>
    </PwaLayout>
  );
};

export default Application;
