import { array, object, string } from "yup";

const myParamsSchemas = object().shape({
  names: array().of(
    object().shape({
      sub: string(),
      val: string(),
    })
  ),
});
export default myParamsSchemas;
