import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../Components/Atom/Button";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import { getStatisticsThunk } from "../../../features/statistics/statisticsServices.ts";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;

  prompt(): void;
}

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const { pwa_id: newId } = useParams();
  const pwa_id = newId || window.location.pathname.split("/").at(-1);
  const url = useAppSelector((state) => state.apk.data?.url);
  const installUrl = useAppSelector((state) => state.apk.data?.installUrl);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      event.preventDefault();
      setDeferredPrompt(() => beforeInstallPromptEvent);
    };

    const appInstalledEvent = () => {
      localStorage.setItem("_pwa_app_id", pwa_id!);
      localStorage.setItem("_pwa_app_url", url);
      window.location.reload();
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", appInstalledEvent);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", appInstalledEvent);
    };
  }, [pwa_id, url]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        }
        setDeferredPrompt(null);
      });

      if (installUrl) {
        dispatch(getStatisticsThunk(installUrl));
      }
    }
  };

  return <Button onClick={handleInstallClick}>Install</Button>;
};

export default InstallButton;
