import styles from "./styles.module.scss";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const GalleryById = () => {
  const imageUrl = process.env.REACT_APP_CLIENT_URL;
  const pwaData = useAppSelector((state) => state.getPwaById?.data?.pwa);

  return (
    <div className={styles.wrapper}>
      {pwaData &&
        pwaData?.template?.images?.map((element: any, index: number) => {
          if (index >= 1) {
            return (
              <div className={styles.image} key={element.id}>
                <img
                  className={styles.img}
                  src={`${imageUrl}${element.url}`}
                  alt={element.url}
                  width="100%"
                  height="100%"
                />
              </div>
            );
          }
          return null;
        })}
    </div>
  );
};

export default GalleryById;
