import { useLocation } from "react-router-dom";
import { useMemo } from "react";
export const convertStateToFormData = <T extends Record<string, string | Blob>>(
  formData: T[],
  key: string
): FormData => {
  const convertedFormData = new FormData();

  formData.forEach((data, index) => {
    Object.entries(data).forEach(([dataKey, value]) => {
      const formattedKey = `${key}[${index}][${dataKey}]`;
      convertedFormData.append(formattedKey, value);
    });
  });

  return convertedFormData;
};
export const convertStateToFormDataObject = (data: {
  [key: string]: any;
}): FormData => {
  const formData = new FormData();

  for (const key in data) {
    if (data?.hasOwnProperty(key)) {
      const value = data[key];

      if (value instanceof FileList && value.length > 0) {
        const file = value[0];
        formData.append(key, file);
      } else {
        formData.append(key, value);
      }
    }
  }

  return formData;
};

export const concatFormData = (
  formData1: FormData,
  formData2: FormData
): FormData => {
  const concatenatedFormData = new FormData();

  for (const [key, value] of formData1.entries()) {
    concatenatedFormData.append(key, value);
  }

  for (const [key, value] of formData2.entries()) {
    concatenatedFormData.append(key, value);
  }

  return concatenatedFormData;
};

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
