import styles from "./TabMenu.module.scss";
import type { FC } from "react";
import TabMenuItem from "./TabMenuItem.tsx";
import { useLocation } from "react-router-dom";
import { TTabMenuProps } from "./types.ts";
import classNames from "classnames";

const TabMenu: FC<TTabMenuProps> = ({ menuData, isComment = false }) => {
  const { pathname } = useLocation();

  return (
    <div
      className={classNames(styles.wrapper, isComment ? styles.comment : "")}
    >
      {menuData.map((element) => (
        <TabMenuItem
          active={pathname === element.routh}
          routh={element.routh}
          title={element.title}
          category={element.category}
          disabled={
            (pathname !== "/domain" && element.id === 1) ||
            (pathname === "/domain" && element.id !== 1)
          }
          id={element.id}
          key={element.id}
        />
      ))}
    </div>
  );
};

export default TabMenu;
