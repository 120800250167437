import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import { notifyError, notifySuccess } from "../../lib/toastify";
import {
  I_LogoutPayload,
  I_LogoutResponse,
  I_UserLoginPayload,
  I_UserLoginResponse,
} from "./types.ts";
import LocalStorageService from "../../common/services/localStorage.ts";

export const login = createAsyncThunk<I_UserLoginResponse, I_UserLoginPayload>(
  "auth/login",
  async ({ email, password }: I_UserLoginPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<I_UserLoginResponse>(
        API.LOGIN,
        {
          email,
          password,
        }
      );

      notifySuccess("LoginSuccessful");
      LocalStorageService.setUserData(response.data.user);
      return response.data;
    } catch (error) {
      // @TODO: replace error message from backend
      notifyError("loginIsNotRight");
      return rejectWithValue("loginIsNotRight");
    }
  }
);

export const registration = createAsyncThunk(
  "auth/register",
  async ({ email, password }: I_UserLoginPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<I_UserLoginResponse>(
        API.REGISTER,
        {
          email,
          password,
        }
      );
      const token = response.data.authorization.token;
      if (token) {
        localStorage.setItem("_accessToken", token);
      }
      notifySuccess("RegistrationSuccessful");
      LocalStorageService.setUserData(response.data.user);
      return response.data;
    } catch (error) {
      notifyError("ThisEmailAddressIsAlready");
      return rejectWithValue("ThisEmailAddressIsAlready");
    }
  }
);

export const getUserData = createAsyncThunk(
  "auth/user",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(API.GET_USER_DATA);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("ThisEmailAddressIsAlready");
    }
  }
);

export const userLogout = createAsyncThunk<I_LogoutResponse, I_LogoutPayload>(
  "auth/logout",
  async ({ token }: { token: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(API.LOGOUT, undefined, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      LocalStorageService.revokeUserData();

      return response.data;
    } catch (error) {
      return rejectWithValue("ThisEmailAddressIsAlready");
    }
  }
);
