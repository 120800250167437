import styles from "./styles.module.scss";
import ModalMUI from "../../Atom/ModalMUI";
import { FC } from "react";
import { TWarningModalProps } from "./types.ts";
import Text from "../../Atom/Text";

const WarningModal: FC<TWarningModalProps> = ({ open, setOpen }) => {
  return (
    <ModalMUI isOpen={open} setIsOpen={setOpen} title="ErrorLogs">
      <div className={styles.wrapper}>
        <Text className={styles.text} text="ThereWereNoEvents" tagName="p" />
      </div>
    </ModalMUI>
  );
};

export default WarningModal;
