import styles from "./style.module.scss";
import MiniInfo from "../../../Components/Atom/MiniInfo";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import DownloadIconGray from "../../../assets/icons/DownloadIconGray.tsx";
import StarIconGray from "../../../assets/icons/StarIconGray.tsx";

const MobileInfo = () => {
  const phoneInformation = useAppSelector(
    (state) => state.getPwaById?.data?.pwa
  );
  return (
    <div className={styles.container}>
      <div className={styles.infoItem}>
        <MiniInfo
          className={styles.infoText}
          title="Rating"
          subTitle={phoneInformation && phoneInformation.avg_rating}
          Icon={StarIconGray}
        />
      </div>
      <div className={styles.infoItem}>
        <MiniInfo
          title={phoneInformation && phoneInformation.app_size}
          Icon={DownloadIconGray}
        />
      </div>
      <div className={styles.infoItem}>
        <MiniInfo
          className={styles.infoText}
          subTitle={`${phoneInformation ? phoneInformation.age : "18"}+`}
          title="Age"
        />
      </div>
    </div>
  );
};

export default MobileInfo;
