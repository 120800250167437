import styles from "./styles.module.scss";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const InfoById = () => {
  const phoneInformation = useAppSelector(
    (state) => state.getPwaById?.data?.pwa
  );

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Additional information</p>
      <div className={styles.container}>
        <div className={styles.block}>
          <p className={styles.info}>Updated</p>
          <p className={styles.date}>
            {phoneInformation && phoneInformation.app_updated_at.slice(0, 10)}
          </p>
        </div>
        <div className={styles.block}>
          <p className={styles.info}>Size</p>
          <p className={styles.date}>{`${
            phoneInformation && phoneInformation.app_size
          } Mb`}</p>
        </div>
        <div className={styles.block}>
          <p className={styles.info}>Installs</p>
          <p className={styles.date}>
            {phoneInformation && phoneInformation.installs_quantity}
          </p>
        </div>
        <div className={styles.block}>
          <p className={styles.info}>Current version</p>
          <p className={styles.date}>
            {phoneInformation && phoneInformation.app_version}
          </p>
        </div>
        <div className={styles.block}>
          <p className={styles.info}>Age</p>
          <p className={styles.date}>18+</p>
        </div>
        <div className={styles.block}>
          <p className={styles.info}>Developer</p>
        </div>
      </div>
    </div>
  );
};

export default InfoById;
