import styles from "./styles.module.scss";
import ModalMUI from "../../Atom/ModalMUI";
import { FC } from "react";
import Phone from "../Phone";
import { TPhoneModalProps } from "./types.ts";

const PhoneModal: FC<TPhoneModalProps> = ({ open, setOpen }) => {
  return (
    <ModalMUI isOpen={open} setIsOpen={setOpen}>
      <div className={styles.block}>
        <div className={styles.wrapper}>
          <Phone />
        </div>
      </div>
    </ModalMUI>
  );
};

export default PhoneModal;
