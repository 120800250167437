import { createSlice } from "@reduxjs/toolkit";
import { updatePwaThunk } from "./updatePwaServices.ts";
import { IInitialStateData } from "./types.ts";

const initialState: IInitialStateData = {
  data: null,
  loading: false,
  error: null,
  pwaUpdate: {
    id: 1,
    value: 1,
    field: "",
  },
};

export const updatePwaSlice = createSlice({
  name: "updatePwa",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value, id } = action.payload;
      state.pwaUpdate = {
        id: id,
        value: value,
        field: field,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePwaThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePwaThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(updatePwaThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { updateField } = updatePwaSlice.actions;
