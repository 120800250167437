import styles from "./Login.module.scss";
import Input from "../../Atom/Input";
import Button from "../../Atom/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import registrationSchemas from "../../../common/Schemas/RegistrationSchemas.ts";
import { login } from "../../../features/auth/authService.ts";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { TDataType } from "./types.ts";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TDataType>({
    mode: "onChange",
    resolver: yupResolver(registrationSchemas),
  });

  const onSubmit: SubmitHandler<TDataType> = (data: TDataType) => {
    dispatch(login(data));
    navigate("/profile");
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <p className={styles.title}>{t("pwa")}</p>
      <div className={styles.lists}>
        <Input
          label="email"
          register={register}
          name="email"
          error={errors.email?.message}
        />
        <Input
          label="password"
          register={register}
          name="password"
          error={errors.password?.message}
          type="password"
        />
        <p className={styles.text} onClick={() => navigate("/reset")}>
          {t("resetPassword")}
        </p>
      </div>
      <div className={styles.bottom}>
        <Button type="submit">{t("login")}</Button>
        <p className={styles.info}>
          {t("DontHaveAnAccount")}
          <span
            className={styles.signin}
            onClick={() => navigate("/register")}
            role="button"
          >
            {t("signUp")}
          </span>
        </p>
      </div>
    </form>
  );
};

export default Login;
