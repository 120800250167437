import type { FC } from "react";
import styles from "./MainLayout.module.scss";
import { TMainLayoutProps } from "./types.ts";
import AuthHeader from "../../../Components/Molecule/AuthHeader";
import SideBar from "../../../Components/Atom/SideBar";
import Footer from "../../../Components/Atom/Footer";

const MainLayout: FC<TMainLayoutProps> = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.menu}>
      <SideBar />
    </div>

    <div className={styles.main}>
      <AuthHeader />

      {children}
    </div>
    <Footer />
  </div>
);

export default MainLayout;
