import { createSlice } from "@reduxjs/toolkit";
import { getPwaThunk } from "./getPwaServices.ts";

// Todo add type

const initialState: any = {
  error: null,
  loading: false,
  data: null,
};

export const getPwaSlice = createSlice({
  name: "getPwa",
  initialState,
  reducers: {
    clearInitialState: (state) => {
      state.error = null;
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPwaThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPwaThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getPwaThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearInitialState } = getPwaSlice.actions;
