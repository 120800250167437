export const API = {
  LOGIN: "login",
  REGISTER: "register",
  GET_USER_DATA: "user",
  LOGOUT: "logout",
  GET_TEMPLATES: "templates",
  GET_TEMPLATE: "templates/",
  EMAIL: "/forgot-password/email",
  CHECK: "/forgot-password/check",
  RESET: "/forgot-password/reset",
  CREATEDPWA: "/pwa/create",
  PWA: "/pwa",
  PUSH_TOKEN: "/pwa-device",
  OWN: "/pwa/own",
};
