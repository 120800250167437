import { createSlice } from "@reduxjs/toolkit";
import { IInitialStateComment } from "./types.ts";

const initialState: IInitialStateComment = {
  data: null,
  loading: false,
  error: null,
  review: [{}],
  answer: [{}],
};

export const customCommentsSlice = createSlice({
  name: "/customComments",
  initialState,
  reducers: {
    addCommentList: (state) => {
      state.review.push({}) && state.answer.push({});
    },
    deleteCommentList: (state, action) => {
      const { id } = action.payload;
      state.review.splice(id, 1) && state.answer.splice(id, 1);
    },
    createAnswer: (state, action) => {
      const { key, value, id } = action.payload;
      if (state.review[id - 1]) {
        state.answer[id - 1][key] = value;
      }
    },

    createReview: (state, action) => {
      const { key, value, id } = action.payload;
      if (state.review[id - 1]) {
        state.review[id - 1][key] = value;
      }
    },
  },
});

export const { addCommentList, deleteCommentList, createAnswer, createReview } =
  customCommentsSlice.actions;
