import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./Input.module.scss";
import type { TInputProps } from "./types.ts";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import EyeIcon from "../../../assets/icons/EyeIcon.tsx";
import VisibleEyeIcon from "../../../assets/icons/VisibleEyeIcon.tsx";

const Input: FC<TInputProps> = ({
  label,
  variant = "standard",
  name,
  onChange,
  register,
  error,
  placeholder,
  type = "text",
  defaultValue,
  subLabel,
  value = "",
  id,
}) => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    onChange && onChange(value);
    if (id && onChange) {
      onChange(value, name, id);
    }
  }, []);
  const handleVisible = () => setVisible(!isVisible);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (id && onChange) {
      onChange(value, name, id);
    }
    onChange && onChange(value);
  };

  return (
    <div className={styles.wrapper}>
      {variant === "standard" && (
        <div className={styles.container}>
          {label && <p className={styles.label}>{t(label)}</p>}
          {type === "password" ? (
            <div className={styles.input_block}>
              <input
                type={!isVisible ? "text" : "password"}
                className={styles.input}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                defaultValue={defaultValue}
                {...(register ? register(name) : null)}
              />
              <div className={styles.icon} onClick={handleVisible}>
                {isVisible ? <EyeIcon /> : <VisibleEyeIcon />}
              </div>
            </div>
          ) : (
            <input
              type="text"
              className={styles.input}
              name={name}
              onChange={handleChange}
              defaultValue={value}
              placeholder={placeholder}
              {...(register ? register(name) : null)}
            />
          )}
        </div>
      )}
      {variant === "outlined" && (
        <div className={styles.container}>
          {type === "password" ? (
            <div className={styles.textField_block}>
              <TextField
                type={!isVisible ? "text" : "password"}
                onChange={handleChange}
                name={name}
                id="standard-basic"
                label={label ? t(label) : undefined}
                variant="standard"
                style={{ width: "100%" }}
                placeholder={placeholder}
                defaultValue={defaultValue}
                {...(register ? register(name) : null)}
              />
              <div className={styles.icon} onClick={handleVisible}>
                {isVisible ? <EyeIcon /> : <VisibleEyeIcon />}
              </div>
            </div>
          ) : (
            <TextField
              onChange={handleChange}
              name={name}
              id="standard-basic"
              label={label ? t(label) : undefined}
              variant="standard"
              style={{ width: "100%" }}
              placeholder={placeholder}
              defaultValue={defaultValue}
              {...(register ? register(name) : null)}
            />
          )}
        </div>
      )}
      {variant === "filled" && (
        <div className={styles.container}>
          {label && <p className={styles.label}>{t(label)}</p>}
          {subLabel && <p className={styles.subLabel}>{t(subLabel)}</p>}
          <TextField
            onChange={handleChange}
            name={name}
            id="standard-basic"
            variant="standard"
            style={{ width: "100%" }}
            placeholder={placeholder}
            defaultValue={value}
            {...(register ? register(name) : null)}
          />
        </div>
      )}
      {error && <p className={styles.error}>{t(error)}</p>}
    </div>
  );
};

export default Input;
