import { createSlice } from "@reduxjs/toolkit";
import { addPushTokenThunk } from "./addPushTokenServices.ts";

const initialState = {
  data: null,
  loading: false,
  error: null,
};
export const addPushTokenSlice = createSlice({
  name: "/addPushToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPushTokenThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addPushTokenThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(addPushTokenThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
