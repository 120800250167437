import styles from "./MiniInfo.module.scss";
import type { FC } from "react";
import type { TMainInfoProps } from "./types.ts";
import classNames from "classnames";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const MiniInfo: FC<TMainInfoProps> = ({ Icon, title, subTitle, className }) => {
  const phoneInformation = useAppSelector((state) => state.getPwa?.data?.pwa);
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.top}>
        {subTitle && (
          <p
            className={classNames(
              styles.title,
              className,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            {subTitle}
          </p>
        )}
        {Icon && <Icon />}
      </div>
      <p
        className={classNames(
          styles.subTitle,
          phoneInformation?.color_schema === "dark" && styles.dark
        )}
      >
        {title}
      </p>
    </div>
  );
};

export default MiniInfo;
