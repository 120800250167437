import styles from "./styles.module.scss";
import { useState } from "react";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const AboutById = () => {
  const [isMore, setIsMore] = useState<boolean>(false);
  const description = useAppSelector(
    (state) => state.getPwaById?.data?.pwa?.app_desc
  );

  const text = description
    ? description
    : "Добро пожаловать в НОВЫЕ СЛОТЫ 2021! Big Win Casino казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!";

  const cropedText = text.slice(0, 60);
  const handleClick = () => {
    setIsMore(!isMore);
  };
  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{isMore ? text : cropedText}</p>
      <p className={styles.btn} role="button" onClick={handleClick}>
        {!isMore ? "More..." : "Less..."}
      </p>
    </div>
  );
};

export default AboutById;
