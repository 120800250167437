import styles from "./Registration.module.scss";
import type { FC } from "react";
import { Registration } from "../../Components";

const RegistrationPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Registration />
    </div>
  );
};
export default RegistrationPage;
