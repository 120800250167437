import styles from "./styles.module.scss";
import Text from "../../Components/Atom/Text";
import Input from "../../Components/Atom/Input";
import Button from "../../Components/Atom/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FbTokensSchemas from "../../common/Schemas/FbTokensSchemas.ts";
import { TFbTokens } from "./types.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import {
  createFbTokenThunk,
  getAllFbTokensThunk,
  showFbTokenThunk,
  updateFbTokenThunk,
} from "../../features/fbTokens/fbTokensServices.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const FacebookTokens = () => {
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("fb_token"));
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(FbTokensSchemas),
  });
  const inputValues = useAppSelector(
    (state) => state.fbToken.showFbToken?.data?.data
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (searchQueryPwaId) {
      dispatch(showFbTokenThunk(searchQueryPwaId));
    }
    return () => {};
  }, []);

  const onSubmit = (data: TFbTokens) => {
    navigate("/fbapi");
    if (searchQueryPwaId) {
      dispatch(
        updateFbTokenThunk({
          id: searchQueryPwaId,
          pixel_id: data.pixel_id,
          token: data.pixel_id,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getAllFbTokensThunk()));
    } else {
      dispatch(createFbTokenThunk(data))
        .unwrap()
        .then((res) => res && dispatch(getAllFbTokensThunk()));
    }
  };

  return (
    <div className={styles.wrapper}>
      <Text className={styles.title} text="MyFacebookTokens" tagName="p" />
      <form className={styles.block} onSubmit={handleSubmit(onSubmit)}>
        <Text tagName="p" text="AddingANewToken" className={styles.text} />
        <Input
          value={inputValues?.pixel_id ? inputValues?.pixel_id : ""}
          name="pixel_id"
          register={register}
          error={errors.pixel_id?.message}
          placeholder="123456789123456789"
          label="Pixel"
        />
        <Input
          value={inputValues?.token || ""}
          name="token"
          error={errors.token?.message}
          register={register}
          placeholder="EAAd......."
          label="Token"
        />

        <Button type="submit">Add</Button>
      </form>
    </div>
  );
};

export default FacebookTokens;
