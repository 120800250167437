import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";

export const viewStatisticsThunk = createAsyncThunk<any, number | string>(
  "/viewStatisticsThunk",
  async (id, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/pwa-pb/statistics?action=reg&pwa_id=${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const installStatisticsThunk = createAsyncThunk<any, number | string>(
  "/installStatisticsThunk",
  async (id, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/pwa-pb/statistics?action=install&pwa_id=${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStatisticsThunk = createAsyncThunk(
  "/getStatisticsThunk",
  async (url: string, thunkAPI) => {
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
