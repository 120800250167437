import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import DeleteIcon from "../../../assets/icons/DeleteIcon.tsx";
import styles from "./styles.module.scss";
import EditIcon from "../../../assets/icons/EditIcon.tsx";
import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import { TMUIProps } from "./types.ts";
import SerarchIcon from "../../../assets/icons/SerarchIcon.tsx";
import PhoneModal from "../../Organism/PhoneModal";
import CopyMUI from "../../Atom/CopyMUI";

const MUITable: FC<TMUIProps> = ({ onClick }) => {
  const navigate = useNavigate();
  const rowsData = useAppSelector((state) => state.ownPwa.data);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const onCopyClick = () => {
    localStorage.removeItem("_pwa_app_id");
    localStorage.removeItem("_pwa_app_url");
  };

  return (
    <TableContainer component={Paper}>
      <PhoneModal open={open} setOpen={setOpen} />
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Domains</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData &&
            rowsData?.pwas.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className={styles.tableRow}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.domain}</TableCell>
                  <TableCell align="left" sx={{ display: "flex", gap: "8px" }}>
                    <div
                      className={styles.icon}
                      onClick={() => onClick(row.id)}
                    >
                      <DeleteIcon />
                    </div>
                    <div className={styles.icon}>
                      <CopyMUI
                        onClick={onCopyClick}
                        text={row.pwa_url as string}
                      />
                    </div>
                    <div className={styles.icon} onClick={handleOpenModal}>
                      <SerarchIcon />
                    </div>
                    <div
                      className={styles.icon}
                      onClick={() => {
                        navigate({
                          pathname: "/design",
                          search: `?pwa_id=${row.id}`,
                        });
                      }}
                    >
                      <EditIcon />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MUITable;
