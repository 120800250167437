import styles from "./styles.module.scss";
import RateMUI from "../../Atom/RateMUI";
import { FC } from "react";
import { TCommentProps } from "./types.ts";

const Comment: FC<TCommentProps> = ({
  avatar_portrait,
  answerText,
  answerDate,
  answerName,
  name,
  published_at,
  text,
  answer,
  starRate,
}) => {
  const imageUrl = process.env.REACT_APP_CLIENT_URL;
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageUrl}${avatar_portrait})` }}
      />
      <div className={styles.block}>
        <div className={styles.titleBlock}>
          <p className={styles.name}>{name}</p>
          <RateMUI rating={starRate} />
        </div>
        <p className={styles.date}>{published_at}</p>
        <p className={styles.text}>{text}</p>
        {answer && (
          <div className={styles.answer}>
            <div className={styles.container}>
              <p className={styles.name}>{answerName}</p>
              <p className={styles.date}>{answerDate?.slice(0, 10)}</p>
            </div>
            <p className={styles.text}>{answerText}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
