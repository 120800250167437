import styles from "./ModalMui.module.scss";
import { Modal } from "@mui/material";
import { FC } from "react";
import { TModalMUIProps } from "./types.ts";
import CloseIcon from "../../../assets/icons/CloseIcon.tsx";
import Text from "../Text";

const ModalMUI: FC<TModalMUIProps> = ({
  title,
  isOpen,
  setIsOpen,
  children,
}) => {
  const handleClose = () => setIsOpen(false);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Text tagName="p" className={styles.text} text={title} />
          <div onClick={handleClose} role="button" className={styles.icon}>
            <CloseIcon />
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalMUI;
