import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import DeleteIcon from "../../../assets/icons/DeleteIcon.tsx";
import styles from "./styles.module.scss";
import EditIcon from "../../../assets/icons/EditIcon.tsx";
import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import { TTokensTableProps } from "./types.ts";
import {
  deleteFbTokenThunk,
  getAllFbTokensThunk,
} from "../../../features/fbTokens/fbTokensServices.ts";
import WarningIcon from "../../../assets/icons/WarningIcon.tsx";
import WarningModal from "../WarningModal";

const TokensTable: FC<TTokensTableProps> = () => {
  const navigate = useNavigate();
  const rowsData: any = useAppSelector(
    (state) => state.fbToken.getAllToken.data?.data
  );
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = (id: number | string) => {
    dispatch(deleteFbTokenThunk(id))
      .unwrap()
      .then((res) => res && dispatch(getAllFbTokensThunk()));
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <TableContainer component={Paper}>
      <WarningModal open={isOpen} setOpen={setIsOpen} />
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">Pixel</TableCell>
            <TableCell align="left">Token</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData &&
            rowsData?.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={styles.tableRow}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.pixel_id}</TableCell>
                <TableCell align="left">{row.token}</TableCell>

                <TableCell align="left" sx={{ display: "flex", gap: "8px" }}>
                  <div
                    className={styles.icon}
                    onClick={() => {
                      navigate({
                        pathname: "/fbtokens",
                        search: `?fb_token=${row.id}`,
                      });
                    }}
                  >
                    <EditIcon />
                  </div>
                  <div className={styles.icon} onClick={handleOpenModal}>
                    <WarningIcon />
                  </div>

                  <div
                    className={styles.icon}
                    onClick={() => handleDelete(row.id)}
                  >
                    <DeleteIcon />
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TokensTable;
