import "./style/local.scss";
import { store } from "./lib/redux/store.ts";
import { Provider } from "react-redux";
import BaseRoutes from "./lib/router/BaseRoutes.tsx";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // useEffect(() => {
  //   if (messaging) {
  //     return onMessage(messaging as unknown as Messaging, (payload) => {
  //       console.log(payload);
  //     });
  //   }
  // }, []);
  return (
    <Provider store={store}>
      <BaseRoutes />
    </Provider>
  );
}

export default App;
