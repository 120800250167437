import styles from "./styles.module.scss";
import Button from "../../Atom/Button";
import UploadDesign from "../uploadDesign";
import { useState } from "react";

const ImageUploadContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.btn}>
        <Button onClick={handleOpen}>Upload own design</Button>
      </div>
      <UploadDesign isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ImageUploadContainer;
