import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";
import ResetPage from "../../pages/ResetPage";
import AuthLayout from "../../common/Layout/AuthLayout/authLayout.tsx";
import ConfirmPage from "../../pages/Confirmpage";

const AuthRoutes: React.FC = () => (
  <AuthLayout>
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegistrationPage />} />
      <Route path="reset" element={<ResetPage />} />
      <Route path="confirm" element={<ConfirmPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </AuthLayout>
);

export default AuthRoutes;
