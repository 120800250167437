import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import LocalStorageService from "../../common/services/localStorage";

export const getDevicesThunk = createAsyncThunk(
  "/getPwaDevices",
  async (id: string | number, thunkAPI) => {
    try {
      const token = LocalStorageService.getToken();
      const response = await axios.get(
        `
https://forapawada.com/api/pwa/${id}/devices`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
