import {initializeApp} from "firebase/app";
import { getMessaging as getMessagingFirebase, isSupported } from "firebase/messaging";
import firebaseConfig from './firebase-config.json';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service

export const messaging = await (async () => {
  try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          return getMessagingFirebase(app);
      }
      console.log('Firebase not supported this browser');
      return null;
  } catch (err) {
      console.log(err);
      return null;
  }
  })();

