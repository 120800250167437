import { createSlice } from "@reduxjs/toolkit";
import { getDevicesThunk } from "./getPwaDevicesServices";

const initialState: any = {
  error: null,
  loading: false,
  data: null,
};

export const getPwaDevicesSlice = createSlice({
  name: "/getPwaDevices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDevicesThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDevicesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getDevicesThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
