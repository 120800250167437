import { useTranslation } from "react-i18next";

const useLanguages = () => {
  const { i18n } = useTranslation();

  const changeLanguages = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
  };

  return changeLanguages;
};

export default useLanguages;
