import styles from "./Analytics.module.scss";
import MUISwitch from "../../MUISwitch";
import CheckboxMUI from "../../Atom/CheckboxMUI";
import HelpIcon from "../../../assets/icons/HelpIcon.tsx";
import RadioMUI from "../../Atom/RadioMUI";
import { ChangeEvent, useEffect, useState } from "react";
import Input from "../../Atom/Input";
import Text from "../../Atom/Text";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import { changeDefaultState } from "../../../features/settings/settingsSlice.ts";

const Analytics = () => {
  // const switchRef = useRef(null)
  const dispatch = useAppDispatch();
  const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);

  const [selectedValue, setSelectedValue] = useState("api");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCheckedAnalitics, setIsCheckedAnalitics] = useState<boolean>(false);

  useEffect(() => {
    const isUpdateState = pwaData?.setting?.facebook_analytics_type;

    if (isUpdateState) {
      setIsChecked(true);
      setSelectedValue(pwaData?.setting?.facebook_analytics_type);
    }

    if (pwaData?.setting?.facebook_pixel === "true") {
      setIsCheckedAnalitics(true);
    }
  }, [pwaData, isChecked]);
  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;
    setIsChecked(val);
    dispatch(changeDefaultState({ facebook_analytics: val }));
  };
  const handleChange = (name: string, value: string) => {
    dispatch(changeDefaultState({ name, value }));
  };

  return (
    <div className={styles.wrapper}>
      <Text className={styles.text} text="Analytics" tagName="p" />
      <div className={styles.switch}>
        <MUISwitch
          isChecked={isChecked || pwaData?.setting?.facebook_pixel}
          onChange={handleChecked}
          label="IWantToConnectAnalytics"
        />
      </div>
      <div
        className={
          isChecked || pwaData?.setting?.facebook_pixel
            ? styles.container
            : styles.disable
        }
      >
        <div className={styles.optimization}>
          <div className={styles.icons}>
            <Text className={styles.text} text="OptimizationType" tagName="p" />
            <div className={styles.icon}>
              <HelpIcon />
            </div>
          </div>
          <div className={styles.block}>
            <RadioMUI
              checked={selectedValue === "api"}
              value={"api"}
              label="API"
              name="type_api"
              onChange={(value) => {
                handleChange("facebook_analytics_type", value);
                setSelectedValue(value);
              }}
            />
            <RadioMUI
              value={"ads"}
              checked={selectedValue === "ads"}
              label="AdsOnly"
              name="type_ads_only"
              onChange={(value) => {
                handleChange("facebook_analytics_type", value);
                setSelectedValue(value);
              }}
            />
            <RadioMUI
              value={"native"}
              checked={selectedValue === "native"}
              label="Native"
              name="type_native"
              onChange={(value) => {
                handleChange("facebook_analytics_type", value);
                setSelectedValue(value);
              }}
            />
          </div>
          <Text
            className={styles.info}
            text={selectedValue === "api" ? "andAlsoAccount" : "AddPixelToThe"}
            tagName="p"
          />
          <CheckboxMUI
            label="ImUsingthe"
            setState={() => {
              handleChange("facebook_pixel", String(!isCheckedAnalitics));
              setIsCheckedAnalitics(!isCheckedAnalitics);
            }}
            state={isCheckedAnalitics}
          />
          <Input
            name="facebook_token"
            onChange={(value) =>
              handleChange("facebook_token", value as string)
            }
            variant="filled"
            subLabel="EnterAPixel"
            value={pwaData?.setting?.facebook_token || ""}
            placeholder="{pixel}"
          />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
