import { TTabMenuItemProps } from "./types.ts";

export const tabMenuItemData: TTabMenuItemProps[] = [
  {
    id: 1,
    title: "domain",
    category: "parkingOnCloudFlare",
    routh: "/domain",
  },
  {
    id: 2,
    title: "design",
    category: "readyAndYourOwn",
    routh: "/design",
  },
  {
    id: 3,
    title: "application",
    category: "settings",
    routh: "/application",
  },
  {
    id: 4,
    title: "settings",
    category: "streamsAndPostbacks",
    routh: "/settings",
  },
];

export const tabMenuItemComment: TTabMenuItemProps[] = [
  {
    id: 1,
    title: "domain",
    category: "parkingOnCloudFlare",
    routh: "/domain",
  },
  {
    id: 2,
    title: "design",
    category: "readyAndYourOwn",
    routh: "/design",
  },
  {
    id: 3,
    title: "application",
    category: "settings",
    routh: "/application",
  },
  {
    id: 4,
    title: "Comment",
    category: "Ratings and reviews",
    routh: "/comment",
  },
  {
    id: 5,
    title: "settings",
    category: "streamsAndPostbacks",
    routh: "/settings",
  },
];
