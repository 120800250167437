import styles from "./PhoneContainer.module.scss";
import type { FC } from "react";
import MiniInfo from "../../Atom/MiniInfo";
import StarIcon from "../../../assets/icons/StarIcon.tsx";
import DownloadIcon from "../../../assets/icons/DownloadIcon.tsx";
import Button from "../../Atom/Button";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import classNames from "classnames";
import StarIconSnow from "../../../assets/icons/StarIconSnow.tsx";
import DownloadIconSnow from "../../../assets/icons/DownloadIconSnow.tsx";

const PhoneContainer: FC = () => {
  const imageUrl = process.env.REACT_APP_CLIENT_URL;
  const phoneInformation = useAppSelector((state) => state.getPwa?.data?.pwa);

  return (
    <div className={styles.wrapper}>
      <div className={styles.informationBlock}>
        <div className={styles.header}>
          <div className={styles.image}>
            {phoneInformation?.template && (
              <img
                src={
                  phoneInformation?.template?.images[0] &&
                  `${imageUrl}${phoneInformation?.template?.images[0].url}`
                }
                alt="avatar"
                width="100%"
                height="100%"
                className={styles.img}
              />
            )}
          </div>
          <div className={styles.block}>
            <p
              className={classNames(
                styles.name,
                phoneInformation?.color_schema === "dark" && styles.dark
              )}
            >
              {phoneInformation ? phoneInformation.name : "Best Slots"}
            </p>
            <div className={styles.info}>
              <p className={styles.author}>
                {phoneInformation ? phoneInformation.author : "Play dev"}
              </p>
              <p className={styles.category}>
                {phoneInformation ? phoneInformation.category : "Casino"}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.infoItem}>
            {phoneInformation?.color_schema === "dark" ? (
              <MiniInfo
                title="Rating"
                subTitle={phoneInformation?.avg_rating || 0}
                Icon={StarIconSnow}
              />
            ) : (
              <MiniInfo
                title="Rating"
                subTitle={phoneInformation?.avg_rating || 0}
                Icon={StarIcon}
              />
            )}
          </div>
          <div className={styles.infoItem}>
            {phoneInformation?.color_schema === "dark" ? (
              <MiniInfo
                title={phoneInformation && phoneInformation.app_size}
                Icon={DownloadIconSnow}
              />
            ) : (
              <MiniInfo
                title={phoneInformation && phoneInformation.app_size}
                Icon={DownloadIcon}
              />
            )}
          </div>
          <div className={styles.infoItem}>
            <MiniInfo
              subTitle={`${phoneInformation ? phoneInformation.age : "18"}+`}
              title="Age"
            />
          </div>
        </div>
        <div>
          <Button variant="small" className={styles.btn}>
            {phoneInformation ? phoneInformation.launch_btn : "Install"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PhoneContainer;
