import React, { FC, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TMenuProps } from "./types.ts";
import ArrowBottomIcon from "../../../assets/icons/ArrowBottomIcon.tsx";

const MenuSelect: FC<TMenuProps> = ({ menuData, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string | number) => {
    onClick && onClick(value);
    handleClose();
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        startIcon={<ArrowBottomIcon />}
        sx={{
          background: "#105b0c",
          display: "flex",
          justifyContent: "center",
        }}
      ></Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ padding: 0, margin: 0 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuData.map((element) => {
          return (
            <MenuItem
              key={element.id}
              onClick={() => {
                handleMenuItemClick(
                  element.value ? element.value : element.name
                );
              }}
            >
              {element.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuSelect;
