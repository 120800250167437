import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import LocalStorageService from "../../common/services/localStorage.ts";

export const ownPwaThunk = createAsyncThunk("ownPwa", async (_, thunkAPI) => {
  try {
    const token = LocalStorageService.getToken();
    const response = await axiosInstance.get(API.OWN, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
