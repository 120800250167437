import styles from "./styles.module.scss";
import Button from "../../Atom/Button";
import { FC, useEffect } from "react";

import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { ownPwaThunk } from "../../../features/ownPwa/ownPwaServices.ts";
import { useNavigate } from "react-router-dom";
import MUITable from "../../Molecule/MUITable";
import { deletePwaThunk } from "../../../features/deletePwa/deletePwaServices.ts";
import { clearInitialState } from "../../../features/getPwa/getPwaSlice.ts";

const CreateNewPwa: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ownPwaThunk());
    dispatch(clearInitialState());
  }, []);

  const handleClick = () => {
    navigate("/domain");
  };

  const handleDelete = (id: number) => {
    dispatch(deletePwaThunk(id))
      .unwrap()
      .then((res) => res && dispatch(ownPwaThunk()));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.text}>My PWAs:</p>
        <div className={styles.btn}>
          <Button onClick={handleClick}>Create new</Button>
        </div>
      </div>

      <MUITable onClick={handleDelete} />
    </div>
  );
};

export default CreateNewPwa;
