import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import LocalStorageService from "../../common/services/localStorage.ts";

export const addPushTokenThunk = createAsyncThunk(
  "addPushToken",
  async (token: string, thunkAPI) => {
    try {
      const auth_token = LocalStorageService.getToken();
      const pwa_id = localStorage.getItem("_pwa_app_id");
      const response = await axiosInstance.post(
        API.PUSH_TOKEN,
        {
          token,
          pwa_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
