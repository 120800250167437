import { FC } from "react";
import Rating from "@mui/material/Rating";
import type { TRateMuiProps } from "./types.ts";

const RateMUI: FC<TRateMuiProps> = ({ rating = 0 }) => {
  return (
    <div>
      <Rating
        name="read-only"
        value={rating}
        readOnly
        sx={{ fontSize: "14px" }}
      />
    </div>
  );
};
export default RateMUI;
