import { FC } from "react";
import { IconProps } from "./types.ts";

const StarIconSnow: FC<IconProps> = ({
  width = "20px",
  height = "20px",
  color = "snow",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2l2.47 7.52h7.8l-6.3 4.85 2.47 7.53-6.47-4.67-6.48 4.67 2.47-7.53-6.3-4.85h7.8"
        fill={color}
      />
    </svg>
  );
};

export default StarIconSnow;
