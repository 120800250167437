import styles from "./styles.module.scss";
import RateMUI from "../../../Components/Atom/RateMUI";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import MobileInfo from "../MobileInfo";
import InstallButton from "./InstallButton.tsx";

const Headers = () => {
  const imageUrl = process.env.REACT_APP_CLIENT_URL;
  const pwaData = useAppSelector((state) => state.getPwaById?.data?.pwa);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.img}>
          <img
            width="100%"
            height="100%"
            src={`${imageUrl}${pwaData?.template?.images[0]?.url}`}
            alt=""
          />
        </div>
        <div className={styles.info}>
          <p className={styles.name}>{pwaData && pwaData?.name}</p>
          <div className={styles.infoBlock}>
            <p className={styles.category}>{pwaData && pwaData?.author}</p>
            <p className={styles.category}>{pwaData && pwaData?.category}</p>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.top}>
          <p className={styles.text}>Editor's Choice</p>
          <RateMUI rating={5} />
        </div>
        <MobileInfo />
        <InstallButton />
      </div>
    </div>
  );
};

export default Headers;
