import styles from "./PostBackItem.module.scss";
import {trackingData} from "../../../Atom/SelectMUI/utils.ts";
import SelectMUI from "../../../Atom/SelectMUI";
import Input from "../../../Atom/Input";
import Text from "../../../Atom/Text";
import {changeInstallEvent} from "../../../../features/settings/settingsSlice.ts";
import {useAppDispatch, useAppSelector} from "../../../../lib/redux/hooks.ts";
import {useEffect} from "react";

const PostBackItem = () => {
    const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);
    const dispatch = useAppDispatch();

    console.log(pwaData?.setting, 'edfe')
    const handleChange = (name: string, value: string) => {
        dispatch(changeInstallEvent({name, value}));
    };

    const handleSelect = (value: string | number, name: string) => {
        dispatch(changeInstallEvent({name, value}));
    };

    useEffect(() => {
        if (pwaData?.setting?.postback?.tracking_url) {
            dispatch(
                changeInstallEvent({
                    name: "tracking_url",
                    value: pwaData?.setting?.postback?.tracking_url,
                })
            );
        } else {
            dispatch(
                changeInstallEvent({
                    name: "tracking_url",
                    value: trackingData[0]?.value,
                })
            );
        }
        dispatch(
            changeInstallEvent({
                name: "install_event_url",
                value: pwaData?.setting?.postback?.install_event_url,
            })
        );
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tracking}>
                <SelectMUI
                    onChange={handleSelect}
                    defaultValue={trackingData[0].value}
                    name="tracking_url"
                    selectMenu={trackingData}
                    label="InWhatParameter"
                />
            </div>
            <div className={styles.lists}>
                <Input
                    onChange={(value) =>
                        handleChange("registration_webhook", value as string)
                    }
                    label="signUp"
                    variant="filled"
                    value={
                        pwaData?.setting?.postback?.registration_webhook ||
                        "https://forapwa.com/action=reg&tracking_id{undefined}"
                    }
                />
                <Input
                    onChange={(value) => handleChange("deposit_webhook", value as string)}
                    label="Deposit"
                    variant="filled"
                    value={
                        pwaData?.setting?.postback?.deposit_webhook ||
                        "https://forapwa.com/action=reg&tracking_id{undefined}"
                    }
                />
            </div>
            <Text
                className={styles.title}
                text="PostbackClickAttribution"
                tagName="p"
            />
            <Input
                onChange={(value) => handleChange("install_event_url", value as string)}
                label="SendInstallevent"
                placeholder="https://tracker.com"
                variant="filled"
                value={pwaData?.setting?.postback?.install_event_url || ""}
            />
        </div>
    );
};

export default PostBackItem;
