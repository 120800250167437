import { FC } from "react";
import { IconProps } from "./types.ts";

const FbIcon: FC<IconProps> = ({ width = "16px", height = "16px" }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <line x1="1" y1="11" x2="11" y2="1" stroke="black" strokeWidth="2" />
      <line x1="1" y1="1" x2="11" y2="11" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export default FbIcon;
