import axios from "axios";

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("_accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

const axiosInstance = createAxiosInstance();

export { axiosInstance };
