import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import {
  ICheckPasswordPayload,
  ICheckPasswordResponse,
  IResetPasswordPayload,
  IResetPasswordResponse,
  ISetEmailPayload,
  ISetEmailResponse,
} from "./types.ts";
import { isAxiosError } from "axios";

export const setEmail = createAsyncThunk<ISetEmailResponse, ISetEmailPayload>(
  "setEmail",
  async ({ email }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(API.EMAIL, { email });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const checkPassword = createAsyncThunk<
  ICheckPasswordResponse,
  ICheckPasswordPayload
>("checkPassword", async ({ email, ref_code }, thunkAPI) => {
  try {
    const response = await axiosInstance.post(API.CHECK, {
      email: email,
      ref_code: ref_code,
    });
    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});

export const confirmPassword = createAsyncThunk<
  IResetPasswordResponse,
  IResetPasswordPayload
>("confirmPassword", async ({ email, password, ref_code }, thunkAPI) => {
  try {
    const response = await axiosInstance.patch(API.RESET, {
      password: password,
      email: email,
      ref_code: ref_code,
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      thunkAPI.rejectWithValue(error);
    }
  }
});
