import { menuItemSelectData } from "./types.ts";

export const buttonData: menuItemSelectData[] = [
  {
    id: 1,
    value: "Play",
  },
  {
    id: 2,
    value: "Open",
  },
];

export const schemasData: menuItemSelectData[] = [
  {
    id: 1,
    value: "Комбо",
    name: "combo",
  },
  {
    id: 2,
    value: "Стандартная",
    name: "standard",
  },
  {
    id: 3,
    value: "Черная",
    name: "dark",
  },
];

export const avatarData: menuItemSelectData[] = [
  {
    id: 1,
    value: "Комбо",
  },
  {
    id: 2,
    value: "Геометрия",
  },
  {
    id: 3,
    value: "Никнеймы",
  },
];

export const variantData: menuItemSelectData[] = [
  {
    id: 1,
    value: "Gaming",
  },
  {
    id: 2,
    value: "Sport",
  },
  {
    id: 3,
    value: "Travel",
  },
  {
    id: 4,
    value: "News",
  },
  {
    id: 5,
    value: "World",
  },
  {
    id: 6,
    value: "Business",
  },
];

export const trackingData: menuItemSelectData[] = [
  {
    id: 5,
    value: "Задать свой",
  },
];
