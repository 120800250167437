import styles from "./Registration.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import registrationSchemas from "../../../common/Schemas/RegistrationSchemas.ts";
import Input from "../../Atom/Input";
import Button from "../../Atom/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { registration } from "../../../features/auth/authService.ts";
import { I_UserLoginPayload } from "../../../features/auth/types.ts";

const Registration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(registrationSchemas),
  });

  const dispatch = useAppDispatch();
  const onSubmit = (data: I_UserLoginPayload) => {
    dispatch(registration(data));
  };

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login");
  };
  return (
    <form
      className={styles.wrapper}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <p className={styles.title}>{t("pwa")}</p>
      <div className={styles.container}>
        <Input
          variant="outlined"
          label="email*"
          name="email"
          register={register}
          error={errors.email?.message}
        />
        <Input
          variant="outlined"
          label="password*"
          name="password"
          register={register}
          error={errors.password?.message}
          type="password"
        />
      </div>

      <Button type="submit">{t("signUp")}</Button>
      <p className={styles.text}>
        {t("DoYouHaveAnAccount")}{" "}
        <span className={styles.login} role="button" onClick={handleNavigate}>
          {t("login")}
        </span>{" "}
      </p>
    </form>
  );
};

export default Registration;
