import styles from "./Instruction.module.scss";
import Button from "../../Atom/Button";
import ModalMUI from "../../Atom/ModalMUI";
import { useState } from "react";
import InstructionContainer from "../../Atom/InstructionContainer";
import Text from "../../Atom/Text";

const InstructionItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <>
      <ModalMUI
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Инструкция по добавлению домена"
      >
        <InstructionContainer />
      </ModalMUI>
      <div className={styles.instruction}>
        <Text className={styles.text} text="BeforeAddingADomain" />
        <div>
          <Button onClick={handleOpen}>Instruction</Button>
        </div>
      </div>
    </>
  );
};

export default InstructionItem;
