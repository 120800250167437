import styles from "./styles.module.scss";
import { FC } from "react";
import Comment from "../../../Components/Molecule/Comment";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const CommentsById: FC = () => {
  const phoneInformation = useAppSelector(
    (state) => state.getPwaById?.data?.pwa
  );
  return (
    <div className={styles.wrapper}>
      {phoneInformation &&
        phoneInformation?.comments?.map((element: any) => {
          return (
            <Comment
              starRate={element?.star_rate}
              answer={element?.answer}
              name={element?.full_name}
              text={element?.text}
              published_at={element?.published_at}
              avatar_portrait={element?.avatar_portrait}
              key={element?.id}
              answerText={element?.answer?.text}
              answerDate={element?.answer?.published_at}
              answerName={element?.answer?.full_name}
            />
          );
        })}
    </div>
  );
};

export default CommentsById;
