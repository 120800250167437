import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import russianDataSet from "./common/locales/ru.json";
import englishDataSet from "./common/locales/en.json";
import LocalStorageService from "./common/services/localStorage.ts";
import { E_Language } from "./common/constant/enumConstans.ts";

i18n.use(initReactI18next).init({
  lng: LocalStorageService.getLanguage() ?? E_Language.ENG,
  resources: {
    RU: {
      translation: russianDataSet,
    },
    ENG: {
      translation: englishDataSet,
    },
  },
  fallbackLng: E_Language.ENG,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
