import styles from "./Checkbox.module.scss";
import { Checkbox } from "@mui/material";
import { FC } from "react";
import { TCheckboxProps } from "./types.ts";
import Text from "../Text";

const CheckboxMUI: FC<TCheckboxProps> = ({ label, setState, state }) => {
  const handleChange = () => {
    if (setState) setState(!state);
  };
  return (
    <div className={styles.wrapper} onClick={handleChange} role="button">
      <Checkbox checked={state} />
      {label && <Text className={styles.label} text={label} />}
    </div>
  );
};

export default CheckboxMUI;
