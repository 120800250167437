import { FC, useEffect } from "react";
import styles from "./Profile.module.scss";
import CreateNewPwa from "../../Components/Organism/CreateNewPwa";

const ProfilePage: FC = () => {
  useEffect(() => {
    localStorage.removeItem("_pwaId");
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.tab}>
        <CreateNewPwa />
      </div>
    </div>
  );
};

export default ProfilePage;
