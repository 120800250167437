import styles from "./Template.module.scss";
import type { FC } from "react";
import { TTemplatesItemProps } from "./types.ts";

const TemplateItem: FC<TTemplatesItemProps> = ({ url }) => {
  return (
    <div className={styles.container}>
      <img
        src={url}
        alt=""
        width="100%"
        height="100%"
        className={styles.image}
      />
    </div>
  );
};

export default TemplateItem;
