import styles from "./Instruction.module.scss";
import Input from "../../Atom/Input";
import InstructionItem from "./InstructionItem.tsx";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { addedDomain } from "../../../features/createdPwa/createdPwaSlice.ts";
import { FC, useEffect } from "react";

const Instruction: FC = () => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string | number) => {
    if (value) {
      dispatch(addedDomain(String(value)));
    }
  };

  useEffect(() => {
    dispatch(addedDomain(null));
  }, []);

  return (
    <form className={styles.wrapper}>
      <Input
        variant="standard"
        label="Домен"
        placeholder="mydomain.ru"
        name="domain"
        onChange={(value) => handleChange(value)}
      />
      <InstructionItem />
    </form>
  );
};

export default Instruction;
