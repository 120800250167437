import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import axios from "axios";

export const apkThunk = createAsyncThunk(
  "apk/fetch",
  async (id: number, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`geo-offer/apk/${id}`);
      if (response.data) {
        await axios.post('https://forapawada.com/update-manifest', {
          name: response?.data?.name,
          icon: `https://forapawada.com${response?.data?.image[0].url}`

        })

      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
