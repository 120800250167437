import styles from "./styles.module.scss";
import { useState } from "react";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import classNames from "classnames";

const About = () => {
  const [isMore, setIsMore] = useState<boolean>(false);
  const description = useAppSelector((state) => state.getPwa.data?.pwa);

  const text = description?.app_desc
    ? description?.app_desc
    : "Добро пожаловать в НОВЫЕ СЛОТЫ 2021! Big Win Casino казино игровые автоматы бесплатно без интернета ждут вас прямо сейчас!";

  const cropedText = text.slice(0, 60);
  const handleClick = () => {
    setIsMore(!isMore);
  };

  return (
    <div className={styles.wrapper}>
      <p
        className={classNames(
          styles.title,
          description?.color_schema === "dark" && styles.dark
        )}
      >
        About
      </p>
      <p className={styles.text}>{isMore ? text : cropedText}</p>
      <p className={styles.btn} role="button" onClick={handleClick}>
        {!isMore ? "More..." : "Less..."}
      </p>
    </div>
  );
};

export default About;
