import type { FC } from "react";
import type { TButtonProps } from "./type.ts";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Button: FC<TButtonProps> = ({
  children,
  type = "button",
  onClick,
  variant = "large",
  className,
}) => {
  const small = {
    [styles.small]: variant === "small", // Remove the array brackets here
  };

  const { t } = useTranslation();

  return (
    <button
      className={classNames(styles.wrapper, small, className)}
      type={type}
      onClick={onClick}
    >
      {t(children as string)}
    </button>
  );
};

export default Button;
