import { FC } from "react";
import { IconProps } from "./types.ts";

const PwaIcon: FC<IconProps> = ({
  width = "30",
  height = "30",
  color = "#FFFFFF",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="2.5" width="14" height="12" rx="2" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66667 4.5C9.48986 4.5 9.32029 4.58429 9.19526 4.73431C9.07024 4.88434 9 5.08783 9 5.3V11.7C9 11.9122 9.07024 12.1157 9.19526 12.2657C9.32029 12.4157 9.48986 12.5 9.66667 12.5H12.3333C12.5101 12.5 12.6797 12.4157 12.8047 12.2657C12.9298 12.1157 13 11.9122 13 11.7V5.3C13 5.08783 12.9298 4.88434 12.8047 4.73431C12.6797 4.58429 12.5101 4.5 12.3333 4.5H9.66667ZM11.6667 6.1H10.3333V10.9H11.6667V6.1Z"
        fill="#193118"
      />
      <path
        d="M3.76667 4.5C3.56333 4.5 3.36833 4.58077 3.22455 4.72455C3.08077 4.86833 3 5.06333 3 5.26667C3 5.47 3.08077 5.665 3.22455 5.80878C3.36833 5.95256 3.56333 6.03333 3.76667 6.03333H6.83333C7.03667 6.03333 7.23167 5.95256 7.37545 5.80878C7.51923 5.665 7.6 5.47 7.6 5.26667C7.6 5.06333 7.51923 4.86833 7.37545 4.72455C7.23167 4.58077 7.03667 4.5 6.83333 4.5H3.76667ZM3.76667 7.56667C3.56333 7.56667 3.36833 7.64744 3.22455 7.79122C3.08077 7.935 3 8.13 3 8.33333C3 8.53667 3.08077 8.73167 3.22455 8.87545C3.36833 9.01923 3.56333 9.1 3.76667 9.1H6.83333C7.03667 9.1 7.23167 9.01923 7.37545 8.87545C7.51923 8.73167 7.6 8.53667 7.6 8.33333C7.6 8.13 7.51923 7.935 7.37545 7.79122C7.23167 7.64744 7.03667 7.56667 6.83333 7.56667H3.76667ZM3 11.4C3 11.1967 3.08077 11.0017 3.22455 10.8579C3.36833 10.7141 3.56333 10.6333 3.76667 10.6333H6.83333C7.03667 10.6333 7.23167 10.7141 7.37545 10.8579C7.51923 11.0017 7.6 11.1967 7.6 11.4C7.6 11.6033 7.51923 11.7983 7.37545 11.9421C7.23167 12.0859 7.03667 12.1667 6.83333 12.1667H3.76667C3.56333 12.1667 3.36833 12.0859 3.22455 11.9421C3.08077 11.7983 3 11.6033 3 11.4Z"
        fill="#193118"
      />
    </svg>
  );
};

export default PwaIcon;
