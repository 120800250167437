import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";

export const customCommentsThunk = createAsyncThunk(
  "/customComments",
  async (
    { id, formData }: { id: number | string; formData: FormData },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post(
        `pwa/${id}/custom-comment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
