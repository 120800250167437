import { FC } from "react";

const DevicesIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px" fill="#ffff" version="1.1" id="Layer_1" viewBox="0 0 496 496" xmlSpace="preserve">
    <g>
        <g>
            <g>
                <path d="M456,40H40C17.944,40,0,57.944,0,80v48v16v288c0,13.232,10.768,24,24,24h144h48h80h88v-16h-32v-32h104     c22.056,0,40-17.944,40-40V80C496,57.944,478.056,40,456,40z M16,128c0-4.416,3.592-8,8-8h192c4.408,0,8,3.584,8,8v8H16V128z      M24,440c-4.408,0-8-3.584-8-8v-24h128v24c0,2.816,0.576,5.488,1.472,8H24z M144,192v168H32v16h112v16H16V152h208v16h-56     C154.768,168,144,178.768,144,192z M304,432c0,4.416-3.592,8-8,8h-80h-48c-4.408,0-8-3.584-8-8v-8h144V432z M304,408H160V216h144     V408z M304,200H160v-8c0-4.416,3.592-8,8-8h128c4.408,0,8,3.584,8,8V200z M296,168h-56v-40c0-13.232-10.768-24-24-24H48V88h400     v128H320v-24C320,178.768,309.232,168,296,168z M320,264h32v48h-32V264z M336,440h-17.472c0.896-2.512,1.472-5.184,1.472-8v-24     h16V440z M480,368c0,13.232-10.768,24-24,24H320v-32h160V368z M480,344H320v-16h48v-80h-48v-16h144V72H32v32h-8     c-2.816,0-5.488,0.576-8,1.472V80c0-13.232,10.768-24,24-24h416c13.232,0,24,10.768,24,24V344z"/>
                <rect x="384" y="248" width="80" height="16"/>
                <rect x="384" y="280" width="80" height="16"/>
                <rect x="384" y="312" width="80" height="16"/>
                <path d="M32,248h96v-80H32V248z M48,184h64v48H48V184z"/>
                <path d="M32,344h96v-80H32V344z M48,280h64v48H48V280z"/>
                <path d="M288,232H176v96h112V232z M272,312h-80v-64h80V312z"/>
                <rect x="176" y="344" width="112" height="16"/>
                <rect x="176" y="376" width="112" height="16"/>
            </g>
        </g>
    </g>
    </svg>
  );
};

export default DevicesIcon;
