import styles from "./styles.module.scss";
import RateMUI from "../../../Components/Atom/RateMUI";
import RatingLineLink from "../RatingLineLink";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const RatingById = () => {
  const phoneInformation = useAppSelector(
    (state) => state.getPwaById?.data?.pwa
  );
  const ratingLine_1 =
    Math.round(phoneInformation?.one_rating_quantity / 1000) * 2;
  const ratingLine_2 =
    Math.round(phoneInformation?.two_rating_quantity / 1000) * 2;
  const ratingLine_3 =
    Math.round(phoneInformation?.three_rating_quantity / 1000) * 2;
  const ratingLine_4 =
    Math.round(phoneInformation?.four_rating_quantity / 1000) * 2;
  const ratingLine_5 =
    Math.round(phoneInformation?.five_rating_quantity / 1000) * 2;
  return (
    <div className={styles.wrapper}>
      <p className={styles.name}>Rating and reviews</p>
      <div className={styles.byId}>
        <p className={styles.title}>Rating and reviews</p>
        <div className={styles.container}>
          <div className={styles.leftBlock}>
            <p className={styles.rating}>{phoneInformation?.avg_rating || 0}</p>
            <div className={styles.stars}>
              <RateMUI rating={Math.round(phoneInformation?.avg_rating)} />
            </div>
            <p className={styles.count}>25000</p>
          </div>
          <div className={styles.ratingBlock}>
            <RatingLineLink number={1} ratingChild={ratingLine_1} />
            <RatingLineLink number={2} ratingChild={ratingLine_2} />
            <RatingLineLink number={3} ratingChild={ratingLine_3} />
            <RatingLineLink number={4} ratingChild={ratingLine_4} />
            <RatingLineLink number={5} ratingChild={ratingLine_5} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingById;
