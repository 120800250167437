import { createSlice } from "@reduxjs/toolkit";
import {
  createFbTokenThunk,
  deleteFbTokenThunk,
  getAllFbTokensThunk,
  showFbTokenThunk,
  updateFbTokenThunk,
} from "./fbTokensServices.ts";
import { IDefaultState } from "../../lib/redux/store.ts";

const createDefaultState = () => ({
  data: null,
  loading: false,
  error: null,
});

interface IFbTokenss {
  createFbToken: IDefaultState<any>;
  updateFbToken: IDefaultState<any>;
  removeFbToken: IDefaultState<any>;
  getAllToken: IDefaultState<any>;
  showFbToken: IDefaultState<any>;
}

const initialState: IFbTokenss = {
  createFbToken: { ...createDefaultState() },
  updateFbToken: { ...createDefaultState() },
  removeFbToken: { ...createDefaultState() },
  getAllToken: { ...createDefaultState() },
  showFbToken: { ...createDefaultState() },
};

export const fbTokenSlice = createSlice({
  name: "/fbToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFbTokenThunk.pending, (state) => {
        state.createFbToken = {
          ...state.createFbToken,
          loading: true,
          error: null,
          data: null,
        };
      })
      .addCase(createFbTokenThunk.fulfilled, (state, action) => {
        state.createFbToken = {
          ...state.createFbToken,
          loading: false,
          error: null,
          data: action.payload,
        };
      })
      .addCase(createFbTokenThunk.rejected, (state, action) => {
        state.createFbToken = {
          ...state.createFbToken,
          loading: false,
          error: action.error.message as string,
          data: null,
        };
      })
      .addCase(getAllFbTokensThunk.pending, (state) => {
        state.getAllToken = {
          ...state.getAllToken,
          loading: true,
          error: null,
          data: null,
        };
      })
      .addCase(getAllFbTokensThunk.fulfilled, (state, action) => {
        state.getAllToken = {
          ...state.getAllToken,
          loading: false,
          error: null,
          data: action.payload,
        };
      })
      .addCase(getAllFbTokensThunk.rejected, (state, action) => {
        state.getAllToken = {
          ...state.getAllToken,
          loading: false,
          error: action.error.message as string,
          data: null,
        };
      });

    ///deleteFbToken
    builder
      .addCase(deleteFbTokenThunk.pending, (state) => {
        state.getAllToken = {
          ...state.removeFbToken,
          loading: true,
          error: null,
          data: null,
        };
      })
      .addCase(deleteFbTokenThunk.fulfilled, (state, action) => {
        state.getAllToken = {
          ...state.removeFbToken,
          loading: false,
          error: null,
          data: action.payload,
        };
      })
      .addCase(deleteFbTokenThunk.rejected, (state, action) => {
        state.getAllToken = {
          ...state.removeFbToken,
          loading: false,
          error: action.error.message as string,
          data: null,
        };
      });
    //updateFbTokens
    builder
      .addCase(updateFbTokenThunk.pending, (state) => {
        state.updateFbToken = {
          ...state.updateFbToken,
          loading: true,
          error: null,
          data: null,
        };
      })
      .addCase(updateFbTokenThunk.fulfilled, (state, action) => {
        state.updateFbToken = {
          ...state.updateFbToken,
          loading: false,
          error: null,
          data: action.payload,
        };
      })
      .addCase(updateFbTokenThunk.rejected, (state, action) => {
        state.updateFbToken = {
          ...state.removeFbToken,
          loading: false,
          error: action.error.message as string,
          data: null,
        };
      });

    //showFbToken

    builder
      .addCase(showFbTokenThunk.pending, (state) => {
        state.showFbToken = {
          ...state.showFbToken,
          loading: true,
          error: null,
          data: null,
        };
      })
      .addCase(showFbTokenThunk.fulfilled, (state, action) => {
        state.showFbToken = {
          ...state.showFbToken,
          loading: false,
          error: null,
          data: action.payload,
        };
      })
      .addCase(showFbTokenThunk.rejected, (state, action) => {
        state.showFbToken = {
          ...state.showFbToken,
          loading: false,
          error: action.error.message as string,
          data: null,
        };
      });
  },
});
