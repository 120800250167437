import { FC, useEffect, useState } from "react";
import styles from "./Design.module.scss";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import { getTemplates } from "../../features/templates/templateService.ts";
import Template from "../../Components/Molecule/Template";
import { updateField } from "../../features/updatePwa/updatePwaSlice.ts";
import PwaLayout from "../../common/Layout/PwaLayout";
import { getPwaThunk } from "../../features/getPwa/getPwaServices.ts";
import { useSearchParams } from "react-router-dom";
import { updatePwaThunk } from "../../features/updatePwa/updatePwaServices.ts";
import ImageUploadContainer from "../../Components/Molecule/imageUploadContainer";

const Design: FC = () => {
  const [searchParams] = useSearchParams();
  const images = useAppSelector((state) => state.template.data);
  const dispatch = useAppDispatch();
  const pwaId =
    localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const [activeTab, setActiveTab] = useState<number | undefined>();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));

  //@TOdo  lucel pwa idi harcy vorvhetev nulla galis
  const handleClick = (id: number, index: number) => {
    if (pwaId) {
      dispatch(updatePwaThunk({ id: pwaId, field: "template_id", value: id }))
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        updatePwaThunk({
          id: searchQueryPwaId,
          field: "template_id",
          value: id,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(searchQueryPwaId)));
    }
    dispatch(
      updateField({ id: searchQueryPwaId, field: "template_id", value: id })
    );
    setActiveTab(index);
  };

  useEffect(() => {
    dispatch(getTemplates(1));

    if (searchQueryPwaId) {
      dispatch(getTemplates(searchQueryPwaId));
    }
  }, [searchQueryPwaId, pwaId]);

  return (
    <PwaLayout>
      <div className={styles.wrapper}>
        <ImageUploadContainer />
        {images &&
          images.map((element, index: number) => (
            <Template
              key={index}
              templateData={element?.images}
              onClick={() => handleClick(element.id, index)}
              activeTab={activeTab}
              index={index}
            />
          ))}
      </div>
    </PwaLayout>
  );
};

export default Design;
