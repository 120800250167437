import styles from "./styles.module.scss";
import CommentLayout from "../../common/Layout/CommentLayout";
import CustomComments from "../../Components/Organism/customComments";
import Button from "../../Components/Atom/Button";
import { useState } from "react";
import { useAppDispatch } from "../../lib/redux/hooks.ts";
import {
  addCommentList,
  deleteCommentList,
} from "../../features/customComments/customCommentsSlice.ts";

const CommentsPage = () => {
  const [commentState, setCommentState] = useState([{ id: 1 }]);
  const dispatch = useAppDispatch();
  const handleAdd = () => {
    setCommentState([...commentState, { id: commentState.length + 1 }]);
    dispatch(addCommentList());
  };

  const handleDelete = (id: number) => {
    const updateComments = commentState.filter((element) => element.id !== id);
    setCommentState(updateComments);
    dispatch(deleteCommentList(id));
  };

  return (
    <CommentLayout>
      <div className={styles.wrapper}>
        {commentState.map((element) => (
          <CustomComments
            id={element.id}
            onClick={handleDelete}
            key={element.id}
          />
        ))}
        <div className={styles.button}>
          <Button onClick={handleAdd}>Add comment</Button>
        </div>
      </div>
    </CommentLayout>
  );
};

export default CommentsPage;
