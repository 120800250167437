import styles from "./Phone.module.scss";
import PhoneContainer from "../../Molecule/PhoneContainer";
import About from "../../Atom/About";
import Rating from "../../Atom/Rating";
import AdditionalInfo from "../../Molecule/AdditionalInfo";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import { getPwaThunk } from "../../../features/getPwa/getPwaServices.ts";
import { useSearchParams } from "react-router-dom";
import CommentsContainer from "../../Molecule/commentsContainer";
import Facebook from "../../../tracking/Facebook";
import classNames from "classnames";
import Gallery from "../../Molecule/Gallery";

const Phone = () => {
  const dispatch = useAppDispatch();
  const pwaId = useAppSelector((state) => state.createdPwa.data?.pwa_id);
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));
  const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);

  useEffect(() => {
    if (pwaId || searchQueryPwaId) {
      dispatch(getPwaThunk(pwaId || searchQueryPwaId));
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Facebook fb_pixel={pwaData?.setting?.facebook_token} />
      <div
        className={classNames(
          styles.container,
          pwaData?.color_schema === "dark" && styles.dark
        )}
      >
        <div
          className={classNames(
            styles.phoneContainer,
            pwaData?.color_schema === "combo" && styles.dark
          )}
        >
          <PhoneContainer />
        </div>
        <div className={styles.block}>
          <div className={styles.gallery}>
            <Gallery data={pwaData?.template?.images} />
          </div>
          <About />
          <Rating />
          <CommentsContainer />
          <AdditionalInfo />
        </div>
      </div>
    </div>
  );
};
export default Phone;
