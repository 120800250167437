import type { FC } from "react";
import styles from "./LoginPage.module.scss";
import { Login } from "../../Components";

const LoginPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Login />
    </div>
  );
};

export default LoginPage;
