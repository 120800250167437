import { FC } from "react";
import styles from "./styles.module.scss";
import ConfirmPassword from "../../Components/Molecule/ConfirmPassword";

const ConfirmPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <ConfirmPassword />
    </div>
  );
};

export default ConfirmPage;
