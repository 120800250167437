import Radio from "@mui/joy/Radio";
import { ChangeEvent, FC } from "react";
import { TRadioProps } from "./types.ts";
import styles from "./Radio.module.scss";
import Text from "../Text";

const RadioMUI: FC<TRadioProps> = ({ checked, label, value, onChange }) => {
  const handleChange = (event: ChangeEvent<any>) => {
    const val = event.target.value as string;
    onChange(val);
  };
  return (
    <div className={styles.wrapper}>
      <Radio
        checked={checked}
        value={value}
        onChange={handleChange}
        variant="outlined"
      />
      {label && <Text className={styles.text} text={label} tagName="p" />}
    </div>
  );
};
export default RadioMUI;
