import styles from "./InstructionContainer.module.scss";
import { FC } from "react";
import {
  instruction1,
  instruction2,
  instruction3,
} from "../../../assets/image";

const InstructionContainer: FC = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>1. Зарегистрируйте аккаунт в cloudflare.com</p>
      <p className={styles.text}>
        2. Нажмите add site в верхнем правом углу страницы
      </p>
      <div className={styles.image}>
        <img src={instruction1} alt="" width="100%" height="100%" />
      </div>
      <p className={styles.text}>
        3. Введите в поле тот же домен, что указали в первом шаге на сайте
        PWA.MARKET для создания PWA.
      </p>
      <div className={styles.image}>
        <img src={instruction2} alt="" width="100%" height="100%" />
      </div>
      <p className={styles.text}>
        4. Выберите бесплатный тариф как показано на картинке и нажмите
        “Продолжить”
      </p>
      <div className={styles.image}>
        <img src={instruction3} alt="" width="100%" height="100%" />
      </div>
    </div>
  );
};
export default InstructionContainer;
