import { createSlice } from "@reduxjs/toolkit";
import { getPhoneData } from "./phoneInfoServices.ts";
import { IGetPhoneDataState } from "./types.ts";

const createDefaultState = () => ({
  data: null,
  error: null,
  loading: false,
});

const initialState: IGetPhoneDataState = {
  phoneData: { ...createDefaultState() },
};

export const phoneInfoSlice = createSlice({
  name: "phoneInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPhoneData.pending, (state) => {
      state.phoneData.loading = true;
    });
    builder.addCase(getPhoneData.fulfilled, (state, action) => {
      state.phoneData.loading = false;
      state.phoneData.data = action.payload;
    });
    builder.addCase(getPhoneData.rejected, (state, action) => {
      state.phoneData.error = action.payload as string;
    });
  },
});
