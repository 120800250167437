import styles from "./styles.module.scss";
import Review from "../../Molecule/Review";
import Answer from "../../Molecule/Answer";
import Button from "../../Atom/Button";
import { FC } from "react";
import { TCustomCommentType } from "./types.ts";
import { Dayjs } from "dayjs";
import {
  createAnswer,
  createReview,
} from "../../../features/customComments/customCommentsSlice.ts";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";

const CustomComments: FC<TCustomCommentType> = ({ onClick, id }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    onClick(id);
  };

  const handleChangeReview = async (
    value: string | number | FormData | Dayjs | null,
    key?: string,
    id?: number
  ) => {
    dispatch(createReview({ key, value, id }));
  };
  const handleChangeAnswer = async (
    value: string | number | FormData | Dayjs | null,
    key?: string,
    id?: number
  ) => {
    dispatch(createAnswer({ key, value, id }));
  };

  return (
    <div className={styles.wrapper}>
      <Review id={id} onChange={handleChangeReview} />
      <Answer id={id} onChange={handleChangeAnswer} />
      <Button onClick={handleClick}>Delete</Button>
    </div>
  );
};

export default CustomComments;
