import { Fragment } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useAppSelector } from "../../lib/redux/hooks.ts";

const data = [
  {
    count: 0,
    date: "2024-04-23",
  },
  {
    count: 0,
    date: "2024-04-24",
  },
  {
    count: 0,
    date: "2024-04-25",
  },
  {
    count: 0,
    date: "2024-04-26",
  },
  {
    count: 0,
    date: "2024-04-27",
  },
  {
    count: 0,
    date: "2024-04-28",
  },
  {
    count: 0,
    date: "2024-04-29",
  },
  {
    count: 0,
    date: "2024-04-30",
  },
  {
    count: 0,
    date: "2024-05-01",
  },
  {
    count: 0,
    date: "2024-05-02",
  },
  {
    count: 0,
    date: "2024-05-03",
  },
  {
    count: 0,
    date: "2024-05-04",
  },
  {
    count: 0,
    date: "2024-05-05",
  },
  {
    count: 0,
    date: "2024-05-06",
  },
  {
    count: 0,
    date: "2024-05-07",
  },
  {
    count: 0,
    date: "2024-05-08",
  },
];

const OneChart = () => {
  const viewData = useAppSelector(
    (state) => state.statistics.installStatistics.data
  );
  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={500}
          height={200}
          data={viewData ? viewData?.data : data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis dataKey="count" />

          <Tooltip />
          <Line type="linear" dataKey="count" stroke="#193118" fill="#00875f" />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export default OneChart;
