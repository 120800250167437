import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const getPwaByIdThunk = createAsyncThunk(
    "/getPwaById",
    async (id: string, thunkAPI) => {
        try {
            const response = await axios.get(`
https://forapawada.com/api/preview/?id=${id}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
