import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import MainRoutes from "./MainRoutes.tsx";
import AuthRoutes from "./AuthRoutes.tsx";
import { useAppDispatch, useAppSelector } from "../redux/hooks.ts";
import { getUserData } from "../../features/auth/authService.ts";
import { Toaster } from "react-hot-toast";
import Loader from "../../Components/Atom/Loader";
import LocalStorageService from "../../common/services/localStorage.ts";
import PwaById from "../../pages/PwaById";

interface Navigator {
  standalone?: boolean;
}

function isPwa() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  const isFullscreen = window.matchMedia("(display-mode: fullscreen)").matches;
  const isMinimalUi = window.matchMedia("(display-mode: minimal-ui)").matches;
  const isNavigatorStandalone =
    (window.navigator as Navigator).standalone === true;

  return isStandalone || isFullscreen || isMinimalUi || isNavigatorStandalone;
}

const PrivatePwa = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isPwa()) {
      const pwa_storage_id = localStorage.getItem("_pwa_app_id");
      if (pwa_storage_id) {
        navigate(`/preview/${pwa_storage_id}`);
      }
    }
  }, [navigate]);

  return null;
};
const BaseRoutes = () => {
  const location = String(window.location.pathname);
  const token = LocalStorageService.getToken();
  const { data: userData, loading } = useAppSelector(
    (state) => state.auth.userData
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) dispatch(getUserData());
  }, [token, dispatch]);

  if (loading) {
    return <Loader />;
  }
  return (
    <BrowserRouter>
      <PrivatePwa />

      {isPwa() || location.includes("/preview") ? (
        <Routes>
          {" "}
          <Route path="/preview/:pwa_id" element={<PwaById />} />{" "}
        </Routes>
      ) : (
        <>{userData ? <MainRoutes /> : <AuthRoutes />}</>
      )}

      <Toaster
        position="top-right"
        reverseOrder={false}
        containerStyle={{
          whiteSpace: "nowrap",
          fontSize: "14px",
          zIndex: 999999,
        }}
      />
    </BrowserRouter>
  );
};

export default BaseRoutes;
