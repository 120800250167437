import { I_User } from "../../features/auth/types.ts";

const LocalStorageService = {
  setToken: (token: string) => {
    localStorage.setItem("_accessToken", token);
  },
  revokeToken: () => {
    localStorage.removeItem("_accessToken");
  },
  getToken: () => {
    return localStorage.getItem("_accessToken");
  },
  getLanguage: () => {
    const language = localStorage.getItem("selectedLanguage");
    if (!language) {
      localStorage.setItem("selectedLanguage", "ENG");
    }

    return language;
  },
  getCreatePwa: () => {
    return localStorage.getItem("_create");
  },
  setCreatePwa: (pwaId: string) => {
    return localStorage.setItem("_create", pwaId);
  },
  revokeCreatePwa: () => {
    localStorage.removeItem("_create");
  },
  getUserData: (): I_User | null => {
    const user = localStorage.getItem("_userData");
    return user ? JSON.parse(user) : null;
  },
  setUserData: (userData: I_User) => {
    localStorage.setItem("_userData", JSON.stringify(userData));
  },
  revokeUserData: () => {
    localStorage.removeItem("_userData");
  },
};

export default LocalStorageService;
