import styles from "./styles.module.scss";
import RateMUI from "../RateMUI";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import RatingLine from "../RatingLine";
import classNames from "classnames";

const Rating = () => {
  const phoneInformation = useAppSelector((state) => state.getPwa?.data?.pwa);
  const ratingLine_1 =
    Math.round(phoneInformation?.one_rating_quantity / 1000) * 2;
  const ratingLine_2 =
    Math.round(phoneInformation?.two_rating_quantity / 1000) * 2;
  const ratingLine_3 =
    Math.round(phoneInformation?.three_rating_quantity / 1000) * 2;
  const ratingLine_4 =
    Math.round(phoneInformation?.four_rating_quantity / 1000) * 2;
  const ratingLine_5 =
    Math.round(phoneInformation?.five_rating_quantity / 1000) * 2;
  return (
    <div className={styles.wrapper}>
      <p
        className={classNames(
          styles.title,
          phoneInformation?.color_schema === "dark" && styles.dark
        )}
      >
        Rating and reviews
      </p>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <p
            className={classNames(
              styles.rating,
              phoneInformation?.color_schema === "dark" && styles.dark
            )}
          >
            {phoneInformation?.avg_rating || 0}
          </p>
          <div className={styles.stars}>
            <RateMUI rating={Math.round(phoneInformation?.avg_rating)} />
          </div>
          <p className={styles.count}>
            {Math.round(
              phoneInformation?.one_rating_quantity +
                phoneInformation?.two_rating_quantity / 1000 +
                phoneInformation?.three_rating_quantity +
                phoneInformation?.four_rating_quantity +
                phoneInformation?.five_rating_quantity
            )}
          </p>
        </div>
        <div className={styles.ratingBlock}>
          <RatingLine number={1} ratingChild={ratingLine_1} />
          <RatingLine number={2} ratingChild={ratingLine_2} />
          <RatingLine number={3} ratingChild={ratingLine_3} />
          <RatingLine number={4} ratingChild={ratingLine_4} />
          <RatingLine number={5} ratingChild={ratingLine_5} />
        </div>
      </div>
    </div>
  );
};

export default Rating;
