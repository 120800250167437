import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance} from "../../common/services/axios.ts";
import {TDetailed} from "./types.ts";

export const detailedThunk = createAsyncThunk<TDetailed[], number>(' detailed',
    async (id: number, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`request-logs?pwa_id={${id}`)
            return response.data
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)