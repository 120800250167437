import styles from "./styles.module.scss";

const WhatsNow = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.name}>Whats new?</p>
      <p className={styles.text}>Новые слоты Time Of Sands</p>
    </div>
  );
};

export default WhatsNow;
