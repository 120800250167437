import styles from "./styles.module.scss";
import Button from "../../Atom/Button";
import { useNavigate } from "react-router-dom";
import Text from "../../Atom/Text";
import TokensTable from "../../Molecule/TokensTable";

const FacebookAnalytics = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ pathname: "/fbtokens", search: location.search });
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <Text className={styles.title} text="MyFacebookTokens" tagName="p" />
        <Button onClick={handleClick}>AddANewOne</Button>
      </div>
      <div className={styles.container}>
        <p className={styles.info}>
          How to get{" "}
          <a
            target="_blank"
            className={styles.link}
            href="https://telegra.ph/EAAd-Token-Facebook-02-09"
          >
            EAAd Token
          </a>{" "}
          /{" "}
          <a
            target="_blank"
            className={styles.link}
            href="https://telegra.ph/EAAJG-Token-Facebook-02-09"
          >
            EAAJ(G) Token
          </a>
        </p>
        <div className={styles.table}>
          <TokensTable />
        </div>
      </div>
    </div>
  );
};

export default FacebookAnalytics;
