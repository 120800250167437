import { IStatistics } from "./types.ts";
import { createSlice } from "@reduxjs/toolkit";
import {
  getStatisticsThunk,
  installStatisticsThunk,
  viewStatisticsThunk,
} from "./statisticsServices.ts";

const createDefaultState = () => ({
  data: null,
  loading: false,
  error: null,
});

const initialState: IStatistics = {
  viewStatistic: { ...createDefaultState() },
  installStatistics: { ...createDefaultState() },
  getInstallStatistics: { ...createDefaultState() },
};

export const statisticsSlice = createSlice({
  name: "/statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewStatisticsThunk.fulfilled, (state, action) => {
      state.viewStatistic = {
        ...initialState,
        loading: false,
        error: null,
        data: action.payload,
      };
    });

    builder.addCase(viewStatisticsThunk.pending, (state) => {
      state.viewStatistic = {
        ...initialState,
        loading: true,
        error: null,
        data: null,
      };
    });

    builder.addCase(viewStatisticsThunk.rejected, (state, action) => {
      state.viewStatistic = {
        ...initialState,
        loading: false,
        error: action.error.message as string,
        data: null,
      };
    });

    builder.addCase(installStatisticsThunk.fulfilled, (state, action) => {
      state.installStatistics = {
        ...initialState,
        loading: false,
        error: null,
        data: action.payload,
      };
    });

    builder.addCase(installStatisticsThunk.pending, (state) => {
      state.installStatistics = {
        ...initialState,
        loading: true,
        error: null,
        data: null,
      };
    });

    builder.addCase(installStatisticsThunk.rejected, (state, action) => {
      state.installStatistics = {
        ...initialState,
        loading: false,
        error: action.error.message as string,
        data: null,
      };
    });

    builder.addCase(getStatisticsThunk.fulfilled, (state, action) => {
      state.getInstallStatistics = {
        ...initialState,
        loading: false,
        error: null,
        data: action.payload,
      };
    });

    builder.addCase(getStatisticsThunk.pending, (state) => {
      state.getInstallStatistics = {
        ...initialState,
        loading: true,
        error: null,
        data: null,
      };
    });

    builder.addCase(getStatisticsThunk.rejected, (state, action) => {
      state.getInstallStatistics = {
        ...initialState,
        loading: false,
        error: action.error.message as string,
        data: null,
      };
    });
  },
});
