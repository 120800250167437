import styles from "./SideBar.module.scss";
import { useTranslation } from "react-i18next";
import { sideBarRoutesData } from "./utils.ts";
import { userLogout } from "../../../features/auth/authService.ts";
import LogoutIcon from "../../../assets/icons/LogoutIcon.tsx";
import LocalStorageService from "../../../common/services/localStorage.ts";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { FC } from "react";

const SideBar: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = LocalStorageService.getToken();

  const handleLogout = () => {
    if (token) dispatch(userLogout({ token }));
  };

  const dispatch = useAppDispatch();
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("pwa")}</h1>
      <div className={styles.container}>
        <div className={styles.routs}>
          {sideBarRoutesData.map((element, index) => {
            return (
              <div
                className={styles.block}
                onClick={() => navigate(element.navigate)}
                key={index}
              >
                <element.Icon />
                <p className={styles.text}>{t(element.name as string)}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.logout} onClick={handleLogout}>
          <LogoutIcon width="20px" height="20px" />
          <p className={styles.text}>{t("logout")}</p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
