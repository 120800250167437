import styles from "./styles.module.scss";
import Headers from "./headers";
import GalleryById from "./galleryById";
import { useEffect } from "react";
import { useAppDispatch } from "../../lib/redux/hooks.ts";

import AboutById from "./AboutById";
import WhatsNow from "./whatsNow";
import RatingById from "./ratingById";
import CommentsById from "./CommentsById";
import InfoById from "./infoById";

import { getPwaByIdThunk } from "../../features/getPwaById/getPwaServices.ts";
import { apkThunk } from "../../features/Apk/apkServices.ts";
import { useAppSelector } from "../../lib/redux/hooks.ts";
import Downloadable from "../../Downloadable.tsx";
import { useNavigate, useNavigation, useParams } from "react-router-dom";

const PwaById = () => {
  const dispatch = useAppDispatch();
  const preview_pwa = useAppSelector((state) => state.getPwaById?.data?.pwa);
  const { pwa_id } = useParams();
  const pwa_storage_id = localStorage.getItem("_pwa_app_id");
  useEffect(() => {
    if (pwa_id) {
      dispatch(getPwaByIdThunk(pwa_id));
    }
  }, [pwa_id]);

  useEffect(() => {
    if (preview_pwa) {
      dispatch(apkThunk(preview_pwa?.id));
    }
  }, [preview_pwa]);

  return (
    <div className={styles.wrapper}>
      {!pwa_storage_id ? (
        <div className={styles.container}>
          <div className={styles.blcok}>
            <Headers />
            <GalleryById />
            <AboutById />
            <WhatsNow />
            <RatingById />
            <CommentsById />
            <InfoById />
          </div>
        </div>
      ) : (
        <Downloadable />
      )}
    </div>
  );
};

export default PwaById;
