import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAllFbTokensResponse,
  ICreateTokenPayload,
  ICreateTokenResponse,
  IDeleteFbTokenResponse,
  IUpdateTokenPayload,
} from "./types.ts";
import { axiosInstance } from "../../common/services/axios.ts";

export const createFbTokenThunk = createAsyncThunk<
  ICreateTokenResponse,
  ICreateTokenPayload
>("/createToken", async ({ pixel_id, token }, thunkAPI) => {
  try {
    const response = await axiosInstance.post("fb-token/create", {
      pixel_id,
      token,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllFbTokensThunk = createAsyncThunk<IAllFbTokensResponse>(
  "/getAllTokens",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get("fb-token/all");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteFbTokenThunk = createAsyncThunk<
  IDeleteFbTokenResponse,
  number | string
>("/deleteFbToken", async (id: number | string, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`fb-token/delete/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateFbTokenThunk = createAsyncThunk<
  ICreateTokenResponse,
  IUpdateTokenPayload
>("/updateFbToken", async ({ id, pixel_id, token }, thunkAPI) => {
  try {
    const response = await axiosInstance.put(`fb-token/update/${id}`, {
      pixel_id,
      token,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const showFbTokenThunk = createAsyncThunk<any, number | string>(
  "/showFbToken",
  async (id: number | string, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`fb-token/show/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
