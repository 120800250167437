import { FC, useEffect, useState } from "react";
import styles from "./Select.module.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TSelectProps } from "./types.ts";
import { styled } from "@mui/material";
import Text from "../Text";

const CustomSelectMui = styled(Select)`
  & .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1.5px solid rgba(117, 117, 117, 0.28);
    opacity: 1 !important;
  }
`;

const SelectMUI: FC<TSelectProps> = ({
  label,
  name,
  onChange,
  selectMenu,
  defaultValue,
}) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value as string;
    setValue(val);
    onChange && onChange(val, name);
  };

  useEffect(() => {
    if (
      defaultValue &&
      selectMenu.some((option) => option.value === defaultValue)
    ) {
      setValue(defaultValue);
    } else if (selectMenu.length > 0) {
      setValue(selectMenu[0].value);
    }
  }, [defaultValue, selectMenu]);

  return (
    <div className={styles.wrapper}>
      {label && <Text tagName="p" className={styles.text} text={label} />}
      <CustomSelectMui
        value={value}
        onChange={handleChange}
        sx={{
          width: "100%",
          padding: 0,
          height: "32px",
          opacity: "0.8",
        }}
      >
        {selectMenu.map((element) => {
          return (
            <MenuItem
              key={element.id}
              value={element.name ? element.name : element.value}
            >
              {element.value}
            </MenuItem>
          );
        })}
      </CustomSelectMui>
    </div>
  );
};

export default SelectMUI;
