import styles from './styles.module.scss';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useAppSelector} from "../../../lib/redux/hooks.ts";


const DetailedTable = () => {
    const rowsData = useAppSelector(state => state.detailed.data)
    return <TableContainer component={Paper}>
        <Table sx={{width: "100%"}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="left">Uniques</TableCell>
                    <TableCell align="left">Install</TableCell>
                    <TableCell align="left">Deposits</TableCell>
                    <TableCell align="left">Reg</TableCell>
                    <TableCell align="left">CR% uniq/install</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rowsData &&
                    rowsData?.map((row: any, index: number) => {
                        return (
                            <TableRow
                                key={row.index}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                className={styles.tableRow}
                            >
                                <TableCell  key={row.index} component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell  key={row.index} align="left">{row.uniqueIpCount}</TableCell>
                                <TableCell  key={row.index} align="left">{row.installCount}</TableCell>
                                <TableCell  key={row.index} align="left">{row.depositsCount}</TableCell>
                                <TableCell  key={row.index} align="left">{row.regCount}</TableCell>
                                <TableCell  key={row.index} align="left">{row.uniqueIpCount != 0 && row.installCount != 0 ?  Math.round((row.uniqueIpCount / row.installCount) * 100):0}</TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default DetailedTable