import styles from "./styles.module.scss";
import ModalMUI from "../../Atom/ModalMUI";
import { FC } from "react";
import { TUploadDesignProps } from "./types"; // Removed '.ts'
import UploadFile from "../../Atom/uploadFile";
import Button from "../../Atom/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import uploadDesignSchemas from "../../../common/Schemas/uploadDesignSchemas.ts";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { uploadDesignThunk } from "../../../features/uploadDesign/uploadDesignServices.ts";
import { useSearchParams } from "react-router-dom";
import { getTemplates } from "../../../features/templates/templateService.ts";

const UploadDesign: FC<TUploadDesignProps> = ({ isOpen, setIsOpen }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(uploadDesignSchemas),
  });

  const dispatch = useAppDispatch();
  const pwaId =
    localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));

  const onSubmit: any = (data: FormData) => {
    const formData = new FormData();
    Object.values(data).forEach((element, index) => {
      const file = element[0];
      formData.append(`images[${index}]`, file);
    });
    if (pwaId) {
      dispatch(
        uploadDesignThunk({
          id: pwaId,
          data: formData,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getTemplates(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        uploadDesignThunk({
          id: searchQueryPwaId,
          data: formData,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getTemplates(searchQueryPwaId)));
    }
    setIsOpen(false);
  };

  return (
    <ModalMUI title="Design uploading" isOpen={isOpen} setIsOpen={setIsOpen}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.block}>
          <UploadFile
            error={errors?.icon?.message}
            register={register}
            name="icon"
            label="Screen (from 720x1280px)"
            text="Choose Design Image"
          />
        </div>
        <div className={styles.container}>
          <UploadFile
            error={errors?.images1?.message}
            register={register}
            name="images1"
            label="Screen (from 720x1280px)"
            text="Choose Design Image"
          />
          <UploadFile
            error={errors?.images2?.message}
            register={register}
            name="images2"
            label="Screen (from 720x1280px)"
            text="Choose Design Image"
          />
        </div>
        <div className={styles.container}>
          <UploadFile
            error={errors?.images3?.message}
            register={register}
            name="images3"
            label="Screen (from 720x1280px)"
            text="Choose Design Image"
          />
          <UploadFile
            error={errors?.images4?.message}
            register={register}
            name="images4"
            label="Screen (from 720x1280px)"
            text="Choose Design Image"
          />
        </div>
        <div className={styles.btn}>
          <Button type="submit">Uploading</Button>
        </div>
      </form>
    </ModalMUI>
  );
};

export default UploadDesign;
