import styles from "./AuthHeader.module.scss";
import { FC } from "react";
import ChangeLanguage from "../../Atom/ChangeLanguage";
import GlobusIcon from "../../../assets/icons/GlobusIcon.tsx";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const AuthHeader: FC = () => {
  const { data: userData } = useAppSelector((state) => state.auth.userData);

  return (
    <header className={userData ? styles.container : styles.wrapper}>
      <div className={styles.select}>
        <ChangeLanguage Icon={GlobusIcon} />
      </div>
      <p>{userData?.email}</p>
    </header>
  );
};

export default AuthHeader;
