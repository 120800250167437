import styles from "./style.module.scss";
import { FC } from "react";
import { TPwaLayoutProps } from "./types.ts";
import TabMenu from "../../../Components/Atom/TabMenu";
import Button from "../../../Components/Atom/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Text from "../../../Components/Atom/Text";
import Phone from "../../../Components/Organism/Phone";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { createdPwaThunk } from "../../../features/createdPwa/createdPwaServices.ts";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import { getPwaThunk } from "../../../features/getPwa/getPwaServices.ts";
import { tabMenuItemData } from "../../../Components/Atom/TabMenu/utils.ts";
import { settingsThunk } from "../../../features/settings/settingsServices.ts";
import { ownPwaThunk } from "../../../features/ownPwa/ownPwaServices.ts";

const PwaLayout: FC<TPwaLayoutProps> = ({ children }) => {
  const location = useLocation();
  const pathName =
    location.pathname === "/domain" || location.pathname === "/settings";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const domain = useAppSelector((state) => state.createdPwa.domain);
  const pwaId =
    localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));
  const defaultSettingState = useAppSelector(
    (state) => state.settings.defaultState
  );

  const handleNext = () => {
    if (location.pathname == "/domain") {
      navigate({ pathname: "/design", search: location.search });
      if (!searchParams.has("pwa_id")) {
        dispatch(createdPwaThunk(domain))
          .unwrap()
          .then((res) => {
            res?.pwa_id && localStorage.setItem("_pwaId", String(res.pwa_id));
            dispatch(getPwaThunk(res.pwa_id));
          });
      }
    } else if (location.pathname === "/design") {
      navigate({ pathname: "/application", search: location.search });
    } else if (location.pathname === "/application") {
      navigate({ pathname: "/settings", search: location.search });
    } else if (location.pathname === "/settings") {
      if (pwaId) {
        dispatch(settingsThunk({ id: pwaId, requestBody: defaultSettingState }))
          .unwrap()
          .then((res) => res && dispatch(ownPwaThunk()));
      }
      if (searchQueryPwaId) {
        dispatch(
          settingsThunk({
            id: searchQueryPwaId,
            requestBody: defaultSettingState,
          })
        )
          .unwrap()
          .then((res) => res && dispatch(ownPwaThunk()));
      }

      localStorage.removeItem("_pwaId");
      navigate({ pathname: "/profile", search: location.search });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <TabMenu menuData={tabMenuItemData} />
        <div className={styles.btn}>
          <Button onClick={handleNext}>
            {location.pathname === "/settings" ? "save" : "next"}
          </Button>
        </div>
      </div>
      <div className={pathName ? styles.content : styles.block}>
        {children}
        {!pathName && (
          <div className={styles.phone_container}>
            <Text className={styles.text} text="Preview" tagName="p" />
            <div className={styles.phone}>
              <Phone />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PwaLayout;
