import { object, mixed } from "yup";

const uploadDesignSchemas = object().shape({
  icon: mixed().required("Icon is required"),
  images1: mixed().required("Image 1 is required"),
  images2: mixed().required("Image 2 is required"),
  images3: mixed().required("Image 3 is required"),
  images4: mixed().required("Image 4 is required"),
});

export default uploadDesignSchemas;
