import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { ISettingsPayload } from "./types.ts";

export const settingsThunk = createAsyncThunk<
  any,
  { id: number; requestBody: ISettingsPayload }
>("/settings", async ({ id, requestBody }, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `/pwa/${id}/final-step`,
      requestBody
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
