import styles from "./styles.module.scss";
import MUISwitch from "../../MUISwitch";
import { ChangeEvent, useState } from "react";
import MyParamsItem from "./MyParamsItem.tsx";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import myParamsSchemas from "../../../common/Schemas/MyParamsSchemas.ts";

const MyParams = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(myParamsSchemas),
    defaultValues: {
      names: [{ sub: "", val: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "names",
  });

  const [isGeoOffer, setIsGeoOffer] = useState<boolean>(false);
  const handleGeoOfferChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGeoOffer(event.target.checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.switch}>
        <MUISwitch
          onChange={handleGeoOfferChange}
          label="IWantToUseParams"
          isChecked={isGeoOffer}
        />
      </div>

      <div className={isGeoOffer ? styles.block : styles.disable}>
        {fields.map((element, index) => (
          <MyParamsItem
            length={fields.length}
            remove={remove}
            append={append}
            errors={errors}
            register={register}
            key={element.id}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default MyParams;
