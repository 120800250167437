import styles from "./styles.module.scss";
import TabMenu from "../../../Components/Atom/TabMenu";
import Button from "../../../Components/Atom/Button";
import { FC } from "react";
import type { TCommentProps } from "./types.ts";
import { tabMenuItemComment } from "../../../Components/Atom/TabMenu/utils.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import { concatFormData, convertStateToFormData } from "../../utils/utils.ts";
import { customCommentsThunk } from "../../../features/customComments/customCommentsServices.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPwaThunk } from "../../../features/getPwa/getPwaServices.ts";

const CommentLayout: FC<TCommentProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { review, answer } = useAppSelector((state) => state.customComments);
  const pwaId =
    localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));
  const navigate = useNavigate();
  const handleNext = () => {
    const updatedDataReview: FormData = convertStateToFormData(
      review,
      "review"
    );
    const updatedDataAnswer: FormData = convertStateToFormData(
      answer,
      "answer"
    );
    const concatenatedFormData = concatFormData(
      updatedDataReview,
      updatedDataAnswer
    );
    navigate({ pathname: "/settings", search: location.search });
    if (pwaId) {
      dispatch(
        customCommentsThunk({
          id: pwaId,
          formData: concatenatedFormData,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(pwaId)));
    }
    if (searchQueryPwaId) {
      dispatch(
        customCommentsThunk({
          id: searchQueryPwaId,
          formData: concatenatedFormData,
        })
      )
        .unwrap()
        .then((res) => res && dispatch(getPwaThunk(searchQueryPwaId)));
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <TabMenu isComment={true} menuData={tabMenuItemComment} />
        <div className={styles.btn}>
          <Button onClick={handleNext}>next</Button>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default CommentLayout;
