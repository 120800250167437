import styles from "./styles.module.scss";
import Input from "../../Atom/Input";
import Button from "../../Atom/Button";
import { FC } from "react";
import type { TMyParamsProps } from "./types.ts";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { createParams } from "../../../features/settings/settingsSlice.ts";

const MyParamsItem: FC<TMyParamsProps> = ({
  register,
  length,
  append,
  remove,
  index,
}) => {
  const dispatch = useAppDispatch();

  const handleAdd = () => {
    append({
      sub: "",
      val: "",
    });
  };

  const handleDelete = () => {
    remove(index);
  };

  const handleChange = (
    value: number | string,
    name: string | undefined,
    id: number | undefined
  ) => {
    dispatch(createParams({ value, name, id }));
  };

  return (
    <div className={styles.form}>
      <div className={styles.formBlock}>
        <Input
          register={register}
          id={Number(index)}
          onChange={handleChange}
          name="sub"
          placeholder="sub1"
        />

        <Input
          register={register}
          id={Number(index)}
          onChange={handleChange}
          name="val"
          placeholder="value1"
        />
      </div>

      {(index == 0 && length === 1) || index === length - 1 ? (
        <Button onClick={handleAdd}>+</Button>
      ) : (
        <Button onClick={handleDelete}>-</Button>
      )}
    </div>
  );
};

export default MyParamsItem;
