import { FC, useEffect } from "react";

import OneChart from "./oneChart.tsx";

import styles from "./FbApi.module.scss";
import TwoChart from "./twoChart.tsx";
import FacebookAnalytics from "../../Components/Organism/FacebookAnalytics";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import { getAllFbTokensThunk } from "../../features/fbTokens/fbTokensServices.ts";
import Text from "../../Components/Atom/Text";
import PwaSelect from "../../Components/Atom/PwaSelect";
import { ownPwaThunk } from "../../features/ownPwa/ownPwaServices.ts";
import DetailedTable from "../../Components/Molecule/DetailedTable";

const FbApi: FC = () => {
  const rowsData: any = useAppSelector((state) => state.ownPwa.data);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllFbTokensThunk());
    dispatch(ownPwaThunk());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <Text className={styles.text} text="DetailedStatistics" tagName="p" />
        <div className={styles.select}>
          <PwaSelect data={rowsData?.pwas} />
        </div>
      </div>
      <Text className={styles.text} text="StatisticsPerWeaks" tagName="p" />
      <div className={styles.container}>
        <OneChart />
        <TwoChart />
      </div>
      <div className={styles.analytics}>
        <FacebookAnalytics />
      </div>
        <div className={styles.detailed}>
            <DetailedTable/>
        </div>
    </div>
  );
};
export default FbApi;
