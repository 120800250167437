import ResetPassword from "../../Components/Molecule/ResetPassword";
import styles from "./ResetPage.module.scss";
const ResetPage = () => {
  return (
    <div className={styles.wrapper}>
      <ResetPassword />
    </div>
  );
};

export default ResetPage;
