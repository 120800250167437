import React from "react";
import styles from "./Settings.module.scss";
import MUISwitch from "../../Components/MUISwitch";
import Input from "../../Components/Atom/Input";
import HelpIcon from "../../assets/icons/HelpIcon.tsx";
import Analytics from "../../Components/Molecule/Analytics";
import Postbacks from "../../Components/Molecule/Postbacks";
import Text from "../../Components/Atom/Text";
import { ChangeEvent, useEffect, useState } from "react";
import PwaLayout from "../../common/Layout/PwaLayout";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks.ts";
import { changeDefaultState } from "../../features/settings/settingsSlice.ts";
import { useSearchParams } from "react-router-dom";
import { getPwaThunk } from "../../features/getPwa/getPwaServices.ts";
import Loader from "../../Components/Atom/Loader";
import MyParams from "../../Components/Molecule/MyParams";

const Settings = () => {
  const [link, setLink] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);
  const loading = useAppSelector((state) => state.getPwa.data?.loading);
  const [searchParams] = useSearchParams();
  const searchQueryPwaId = Number(searchParams.get("pwa_id"));
  const pwaId =
      localStorage.getItem("_pwaId") && Number(localStorage.getItem("_pwaId"));
  const [isGeoOffer, setIsGeoOffer] = useState<boolean>(false);

  useEffect(() => {
    if (pwaId) {
      dispatch(getPwaThunk(pwaId));
    }
    if (searchQueryPwaId) {
      dispatch(getPwaThunk(searchQueryPwaId));
    }
    if (pwaData?.setting?.multi_geo_offer) {
      setIsGeoOffer(true);
    }
  }, [pwaData?.setting?.multi_geo_offer]);

  const handleGeouOffer = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGeoOffer(event.target.checked);
  };

  const handleLink = (event: ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.checked);
  };

  const handleChange = (name: string, value: string) => {
    dispatch(changeDefaultState({ name, value }));
  };

  return (
      <>
        {!loading ? (
            <PwaLayout>
              <div className={styles.wrapper}>
                <div className={styles.description}>
                  <Text className={styles.text} text="LaunchOnUS" tagName="p" />
                  <Text className={styles.text} text="WeAlsoDoNot" tagName="p" />
                </div>
                <div className={styles.offer}>
                  <div className={styles.offerSubTitle}>
                    <Text className={styles.text} text="Offer" tagName="p" />
                  </div>
                  <div className={styles.container}>
                    <div className={styles.switch}>
                      <MUISwitch
                          onChange={handleGeouOffer}
                          label="MultyGeoOffer"
                          isChecked={isGeoOffer}
                      />
                    </div>

                    <div className={isGeoOffer ? styles.block : styles.disable}>
                      <Input
                          onChange={(value) =>
                              handleChange("multi_geo_offer", value as string)
                          }
                          name="multi_geo_offer"
                          variant="filled"
                          label="DefaultLink"
                          placeholder="https://landing.com/offer"
                          value={pwaData?.setting?.multi_geo_offer || ""}
                      />
                      <div className={styles.icon}>
                        <HelpIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <MyParams />
                <Analytics />
                <Postbacks />
              </div>
            </PwaLayout>
        ) : (
            <Loader />
        )}
      </>
  );
};

export default Settings;
