import {createSlice} from "@reduxjs/toolkit";
import {detailedThunk} from "./detailedServices.ts";
import {IInitialStateData} from "./types.ts";

const initialState: IInitialStateData = {
    data: null,
    loading: false,
    error: null,
};

export const detailedSlice = createSlice({
    name: 'detailed',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(detailedThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(detailedThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(detailedThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message as string;
                state.data = null;
            });
    },
});


