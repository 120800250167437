import styles from "./Template.module.scss";
import { FC } from "react";
import TemplateItem from "./TemplateItem.tsx";
import { TTemplateProps } from "./types.ts";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import { Skeleton } from "@mui/material";

const Template: FC<TTemplateProps> = ({
  templateData,
  activeTab,
  index,
  onClick,
}) => {
  const isActiveTab = activeTab === index ? styles.isActive : "";
  const imageUrl = process.env.REACT_APP_CLIENT_URL;
  const loading = useAppSelector((state) => state.template.loading);

  return (
    <>
      {loading ? (
        <div className={styles.skeleton}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={114}
            height={114}
          />
          <Skeleton
            variant="rectangular"
            width={101}
            height={180}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            width={101}
            height={180}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            width={101}
            height={180}
            animation="wave"
          />
        </div>
      ) : (
        <div
          className={`${styles.wrapper} ${isActiveTab}`}
          onClick={onClick}
          role="button"
        >
          {templateData.map((element) => (
            <TemplateItem url={`${imageUrl}${element.url}`} key={element.id} />
          ))}
        </div>
      )}
    </>
  );
};

export default Template;
