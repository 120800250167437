import { getUserData, login, registration, userLogout } from "./authService.ts";
import { I_User } from "./types.ts";
import { createSlice } from "@reduxjs/toolkit";
import { IDefaultState } from "../../lib/redux/store.ts";
import LocalStorageService from "../../common/services/localStorage.ts";

const createDefaultState = () => ({
  data: null,
  loading: false,
  error: null,
});

interface AuthState {
  userData: IDefaultState<I_User>;
}

const initialState: AuthState = {
  userData: {
    ...createDefaultState(),
    data: LocalStorageService.getUserData(),
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //login
    builder.addCase(login.pending, (state) => {
      state.userData = { data: null, loading: true, error: null };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.userData = {
        ...state.userData,
        data: action.payload.user,
        loading: false,
      };
      LocalStorageService.setToken(action.payload.authorization.token);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.userData = {
        ...state.userData,
        error: action.payload as string,
        loading: false,
      };
    });
    //register
    builder.addCase(registration.pending, (state) => {
      state.userData = { data: null, loading: true, error: null };
    });
    builder.addCase(registration.fulfilled, (state, action) => {
      state.userData = {
        ...state.userData,
        data: action.payload.user,
        loading: false,
      };
    });
    builder.addCase(registration.rejected, (state, action) => {
      state.userData = {
        ...state.userData,
        error: action.error.message,
        loading: false,
      };
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userData = {
        ...state.userData,
        data: action.payload.data,
        loading: false,
      };
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.userData = {
        ...state.userData,
        error: action.error.message,
        loading: false,
      };
    });
    builder.addCase(getUserData.pending, (state) => {
      state.userData = { data: null, loading: true, error: null };
    });
    builder.addCase(userLogout.fulfilled, (state) => {
      state.userData = {
        ...state.userData,
        data: null,
        loading: false,
      };
      LocalStorageService.revokeToken();
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.userData = {
        ...state.userData,
        error: action.error.message,
        loading: false,
      };
    });
    builder.addCase(userLogout.pending, (state) => {
      state.userData = { data: null, loading: true, error: null };
    });
  },
});
