import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.text}>Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
