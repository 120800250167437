import { FC } from "react";

const HelpIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="14"
      height="22"
      viewBox="0 0 256 256"
      fill="#fff"
    >
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M45 58.88c-2.209 0-4-1.791-4-4v-4.543c0-1.101 0.454-2.153 1.254-2.908l8.083-7.631c1.313-1.377 2.035-3.181 2.035-5.087v-0.302c0-2.005-0.791-3.881-2.228-5.281c-1.436-1.399-3.321-2.14-5.342-2.089c-3.957 0.102-7.175 3.523-7.175 7.626c0 2.209-1.791 4-4 4s-4-1.791-4-4c0-8.402 6.715-15.411 14.969-15.623c4.183-0.109 8.138 1.439 11.131 4.357c2.995 2.918 4.645 6.829 4.645 11.01v0.302c0 4.027-1.546 7.834-4.354 10.72c-0.04 0.041-0.08 0.081-0.121 0.12L49 52.062v2.818C49 57.089 47.209 58.88 45 58.88z"
          transform="matrix(1 0 0 1 0 0)"
          strokeLinecap="round"
        />
        <path
          d="M45 71.96c-1.32 0-2.61-0.53-3.54-1.46c-0.23-0.23-0.43-0.49-0.62-0.76c-0.18-0.271-0.33-0.561-0.46-0.86c-0.12-0.311-0.22-0.62-0.28-0.94c-0.07-0.32-0.1-0.65-0.1-0.98c0-0.32 0.03-0.65 0.1-0.97c0.06-0.32 0.16-0.641 0.28-0.94c0.13-0.3 0.28-0.59 0.46-0.86c0.19-0.279 0.39-0.529 0.62-0.76c1.16-1.16 2.89-1.7 4.52-1.37c0.32 0.07 0.629 0.16 0.93 0.29c0.3 0.12 0.59 0.28 0.859 0.46c0.28 0.181 0.53 0.391 0.761 0.62c0.239 0.23 0.439 0.48 0.63 0.76c0.18 0.271 0.33 0.561 0.46 0.86c0.12 0.3 0.22 0.62 0.279 0.94C49.97 66.31 50 66.64 50 66.96c0 0.33-0.03 0.66-0.101 0.979c-0.06 0.32-0.159 0.63-0.279 0.94c-0.13 0.3-0.28 0.59-0.46 0.86c-0.19 0.27-0.391 0.529-0.63 0.76c-0.23 0.229-0.48 0.439-0.761 0.62c-0.27 0.18-0.56 0.34-0.859 0.46c-0.301 0.13-0.61 0.22-0.93 0.279C45.65 71.93 45.33 71.96 45 71.96z"
          transform="matrix(1 0 0 1 0 0)"
          strokeLinecap="round"
        />
        <path
          d="M45 90C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45S69.813 90 45 90zM45 8C24.598 8 8 24.598 8 45s16.598 37 37 37s37-16.598 37-37S65.402 8 45 8z"
          transform="matrix(1 0 0 1 0 0)"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default HelpIcon;
