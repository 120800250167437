import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createdPwaThunk } from "./createdPwaServices.ts";
import { TCreatedPwaState } from "./types.ts";

const initialState: TCreatedPwaState = {
  data: null,
  domain: { domain: null },
  error: null,
  loading: false,
};

export const createdPwaSlice = createSlice({
  name: "createdPwa",
  initialState,
  reducers: {
    addedDomain: (state, action: PayloadAction<string | null>) => {
      state.domain = { domain: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createdPwaThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createdPwaThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(createdPwaThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  },
});

export const { addedDomain } = createdPwaSlice.actions;

export default createdPwaSlice.reducer;
