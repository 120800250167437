import { FC, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { TRangeSliderProps } from "./types.ts";
import styles from "./RangerSlider.module.scss";
import { styled } from "@mui/material";
import Text from "../Text";

const CustomSlider = styled(Slider)`
  & .MuiSlider-rail {
    background-color: #105b0c;
    opacity: 0.8;
  }

  & .MuiSlider-thumb::before {
    background: #105b0c;
  }

  & .MuiSlider-track {
    background-color: #757575;
    border: none;
  }
`;

const RangeSliderMui: FC<TRangeSliderProps> = ({
  label,
  defaultValue,
  onChange,
  max = 5000,
  name,
}) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: any) => {
    const val = event.target.value;
    setValue(val);
    onChange && onChange(val, name);
  };
  useEffect(() => {
    if (defaultValue && defaultValue > 0) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <div className={styles.wrapper}>
      {label && <Text className={styles.text} text={label} tagName="p" />}
      <CustomSlider
        onChange={handleChange}
        defaultValue={defaultValue ? defaultValue : value}
        value={value}
        aria-label="Default"
        valueLabelDisplay="auto"
        max={max}
      />
    </div>
  );
};
export default RangeSliderMui;
