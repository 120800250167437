import styles from "./Postbacks.module.scss";
import MUISwitch from "../../MUISwitch";
import PostBackItem from "./PostBackItem";
import Text from "../../Atom/Text";
import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks.ts";
import { changeDefaultState } from "../../../features/settings/settingsSlice.ts";

const PostBacks = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const pwaData = useAppSelector((state) => state.getPwa.data?.pwa);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setIsChecked(event.target.checked);
    dispatch(changeDefaultState({ name: "has_postback", value: value }));
  };

  return (
    <div className={styles.wrapper}>
      <Text className={styles.text} text="Postbacks" tagName="p" />
      <Text className={styles.description} text="YouDontHave" tagName="p" />
      <div className={styles.switch}>
        <MUISwitch
          onChange={handleChecked}
          isChecked={pwaData?.setting?.has_postback || isChecked}
          label="IWantToUseAd"
        />
      </div>
      <div
        className={
          isChecked || pwaData?.setting?.has_postback
            ? styles.visible
            : styles.hidden
        }
      >
        <PostBackItem />
      </div>
    </div>
  );
};
export default PostBacks;
