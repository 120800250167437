import { createSlice } from "@reduxjs/toolkit";
import { apkThunk } from "./apkServices.ts";

interface IInitialState {
  data: null | any;
  loading: boolean;
  error: null | string;
}

const initialState: IInitialState = {
  data: null,
  loading: false,
  error: null,
};

export const apkSlice = createSlice({
  name: "/apk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(apkThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apkThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(apkThunk.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});
