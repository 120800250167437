import styles from "./styles.module.scss";
import Text from "../../Atom/Text";
import Input from "../../Atom/Input";
import DatePikerMUI from "../../Atom/DatePikerMUI";
import UploadFile from "../../Atom/uploadFile";
import { FC } from "react";
import { TReviewProps } from "./types.ts";

const Review: FC<TReviewProps> = ({ onChange, id }) => {
  return (
    <div className={styles.wrapper}>
      <Text className={styles.title} text="Review" tagName="p" />
      <div className={styles.container}>
        <div className={styles.block}>
          <Input
            id={id}
            name="full_name"
            onChange={onChange}
            label="Author"
            variant="filled"
          />
          <Input
            id={id}
            name="star_rate"
            onChange={onChange}
            label="Rating"
            variant="filled"
          />
        </div>
        <div className={styles.block}>
          <Input
            name="likes"
            onChange={onChange}
            id={id}
            label="Likes"
            variant="filled"
          />
          <div className={styles.date}>
            <DatePikerMUI
              id={id}
              label="Date"
              onChange={onChange}
              name="published_at"
            />
          </div>
        </div>
      </div>
      <div className={styles.file}>
        <UploadFile
          id={id}
          name="avatar_portrait"
          onChange={onChange}
          label="Avatar (48x48px) (48x48px)"
        />
      </div>
      <Input
        name="text"
        id={id}
        onChange={onChange}
        label="Comment"
        variant="filled"
      />
    </div>
  );
};

export default Review;
