import styles from "./styles.module.scss";
import Button from "../../Components/Atom/Button";
import { useNavigate } from "react-router-dom";
import { MouseEvent } from "react";

const errorPage = () => {
  const navigate = useNavigate();
  const handleNavigate = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    navigate("/login");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.title}>Oh No!</p>
        <p className={styles.text}>
          Oops! It seems like the email address or reference code you entered is
          incorrect or doesn't exist in our system.
        </p>
      </div>
      <Button onClick={handleNavigate}>Go Back</Button>
    </div>
  );
};

export default errorPage;
