import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { I_DataResponse } from "./types.ts";

export const getTemplates = createAsyncThunk<I_DataResponse[], number | string>(
  "templates/",
  async (id, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/pwa/${id}/templates`);

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
