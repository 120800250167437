import { createSlice } from "@reduxjs/toolkit";
import { getTemplates } from "./templateService.ts";
import { I_TemplatesState } from "./types.ts";

const initialState: I_TemplatesState = {
  data: null,
  loading: false,
  error: null,
};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});
