import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import LocalStorageService from "../../common/services/localStorage.ts";

export const getPwaThunk = createAsyncThunk(
  "/getPwa",
  async (id: number, thunkAPI) => {
    try {
      const token = LocalStorageService.getToken();
      const response = await axiosInstance.get(`${API.PWA}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
