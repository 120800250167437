import { createSlice } from "@reduxjs/toolkit";
import { IResetPassword } from "./types.ts";
import {
  checkPassword,
  confirmPassword,
  setEmail,
} from "./resetPasswordServices.ts";

const createDefaultState = () => ({
  data: null,
  error: null,
  loading: false,
});

const initialState: IResetPassword = {
  setEmailData: { ...createDefaultState() },
  checkPasswordData: { ...createDefaultState() },
  resetPasswordData: { ...createDefaultState() },
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setEmail.pending, (state) => {
      state.setEmailData.loading = true;
    });
    builder.addCase(setEmail.fulfilled, (state, action) => {
      state.setEmailData.loading = false;
      state.setEmailData.data = action.payload;
    });
    builder.addCase(setEmail.rejected, (state, action) => {
      state.setEmailData.error = action.payload as string;
    });

    builder.addCase(checkPassword.pending, (state) => {
      state.checkPasswordData.loading = true;
    });
    builder.addCase(checkPassword.fulfilled, (state, action) => {
      state.checkPasswordData.loading = false;
      state.checkPasswordData.data = action.payload;
    });
    builder.addCase(checkPassword.rejected, (state, action) => {
      state.checkPasswordData.error = action.payload as string;
    });

    builder.addCase(confirmPassword.pending, (state) => {
      state.resetPasswordData.loading = true;
    });
    builder.addCase(confirmPassword.fulfilled, (state, action) => {
      state.resetPasswordData.loading = false;
      state.resetPasswordData.data = action.payload;
    });
    builder.addCase(confirmPassword.rejected, (state, action) => {
      state.resetPasswordData.error = action.payload as string;
    });
  },
});
