import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";

export const uploadDesignThunk = createAsyncThunk<
  any,
  { id: number | string; data: FormData }
>("/uploadDesign", async ({ id, data }, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `/pwa/${id}/templates/upload-files`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
