import styles from "./AutoSelect.module.scss";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import MenuSelect from "./menu.tsx";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import { TAutoSelectProps } from "./types.ts";
import { styled } from "@mui/material";
import Text from "../../Atom/Text";

const CustomTextarea = styled(Textarea)`
  && {
    height: 240px;
    overflow-y: scroll;
    background: transparent;
    resize: none;
    border: none;
    border-radius: 0;
    border-bottom: 1.5px solid rgba(117, 117, 117, 0.28);
    box-shadow: none;

    &:before {
      margin: 0;
    }

    &.Mui-focused {
      border-bottom: 1.5px solid #105b0c;
      transition: 0.5s;
    }

    &:focus {
      border-bottom: 1.5px solid #4caf50;
      outline: none;
    }
  }
`;

const AutoSelect: FC<TAutoSelectProps> = ({
  placeholder,
  label,
  variant = "input",
  menuData,
  value,
  onChange,
  onBlur,
  defaultValue = "",
}) => {
  const [inputValue, setInputValue] = useState<string | number>("");
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (String(value).trim()) {
      setInputValue(value);
    } else {
      setInputValue(defaultValue);
    }
  }, [value, defaultValue]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    onChange && onChange(value);
  };
  const handleChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputValue(value);
    onChange && onChange(value);
  };
  const handleClick = (value: string | number) => {
    inputRef?.current?.focus();
    setInputValue(value);
    onChange && onChange(value);
  };

  const handleBlur = () => {
    onBlur && onBlur(inputValue);
  };
  return (
    <>
      {variant === "input" ? (
        <div className={styles.wrapper}>
          {label && <Text className={styles.text} tagName="p" text={label} />}
          <div className={styles.block}>
            <MenuSelect
              menuData={menuData}
              selectedValue={value}
              onClick={(value) => handleClick(value)}
            />
            <TextField
              inputRef={inputRef}
              id="standard-basic"
              variant="standard"
              placeholder={placeholder}
              sx={{ width: "100%" }}
              value={inputValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.selectList}>
            <MenuSelect
              menuData={menuData}
              selectedValue={value}
              onClick={(value) => handleClick(value)}
            />
            {label && <Text className={styles.text} tagName="p" text={label} />}
          </div>

          <CustomTextarea
            defaultValue={defaultValue}
            value={inputValue}
            sx={{ "--Textarea-focusedInset": "none" }}
            name="Size"
            onChange={handleChangeTextarea}
            onBlur={handleBlur}
            ref={inputRef}
          />
        </div>
      )}
    </>
  );
};

export default AutoSelect;
