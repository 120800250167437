import styles from "./ResetPassword.module.scss";
import Input from "../../Atom/Input";
import Button from "../../Atom/Button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ResetPasswordSchemas from "../../../common/Schemas/ResetPasswordSchemas.ts";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../lib/redux/hooks.ts";
import { setEmail } from "../../../features/resetPassword/resetPasswordServices.ts";
import { ISetEmailPayload } from "../../../features/resetPassword/types.ts";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ResetPasswordSchemas),
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onSubmit = (data: ISetEmailPayload) => {
    dispatch(setEmail(data));
  };
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login");
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <p className={styles.title}>{t("pwa")}</p>
      <Input
        label="email"
        register={register}
        name="email"
        error={errors.email?.message}
      />
      <Button type="submit">{t("reset")}</Button>
      <p className={styles.text}>
        {t("rememberYourPassword")}{" "}
        <span className={styles.login} role="button" onClick={handleNavigate}>
          {t("login")}.
        </span>
      </p>
    </form>
  );
};

export default ResetPassword;
