import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import { IUpdatePwaPayload, IUpdatePwaResponse } from "./types.ts";

export const updatePwaThunk = createAsyncThunk<
  IUpdatePwaResponse,
  IUpdatePwaPayload
>("updatePwa", async ({ id, value, field }, thunkAPI) => {
  try {
    const response = await axiosInstance.patch(`${API.PWA}/${id}`, {
      value,
      field,
    });
    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
