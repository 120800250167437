import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import type { TPhoneInfoPayload, I_PhoneInfoResponse } from "./types.ts";

export const getPhoneData = createAsyncThunk<
  I_PhoneInfoResponse,
  TPhoneInfoPayload
>("phoneInfo/", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`${API.GET_TEMPLATE}${id}`);
    return response.data.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
