import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../common/services/axios.ts";
import { API } from "../../common/constant/apiConstant.ts";
import { ICreatedPwaPayload, ICreatedPwaResponse } from "./types.ts";

export const createdPwaThunk = createAsyncThunk<
  ICreatedPwaResponse,
  ICreatedPwaPayload
>("/createdPwa", async ({ domain }, thunkAPI) => {
  try {
    if (domain) {
      const response = await axiosInstance.post(API.CREATEDPWA, { domain });
      return response.data;
    } else {
      const response = await axiosInstance.post(API.CREATEDPWA);
      return response.data;
    }
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
