import { createSlice } from "@reduxjs/toolkit";
import { IInitialState } from "./types.ts";
import { settingsThunk } from "./settingsServices.ts";
import { IParams } from "./types.ts";

const initialState: IInitialState = {
  data: null,
  loading: false,
  error: null,
  defaultState: {
    multi_geo_offer: "",
    whitepage_url: "",
    facebook_analytics: true,
    facebook_analytics_type: "",
    facebook_pixel: "",
    facebook_token: "",
    has_postback: false,
    postback: {
      install_event_url: "",
      tracking_url: "",
      registration_webhook: "",
      deposit_webhook: "",
    },
    params: [
      {
        sub: "",
        value: "",
      },
    ],
  },
};

export const settingSlice = createSlice({
  name: "/settings",
  initialState,
  reducers: {
    changeDefaultState: (state, action) => {
      const { name, value } = action.payload;
      (state.defaultState as any)[name] = value;
    },
    changeDefault: (state) => {
      state.defaultState.multi_geo_offer = "";
      state.defaultState.whitepage_url = "";
      state.defaultState.facebook_analytics = true;
      state.defaultState.facebook_analytics_type = "";
      state.defaultState.facebook_pixel = "";
      state.defaultState.facebook_token = "";
      state.defaultState.postback.install_event_url = "";
      state.defaultState.postback.tracking_url = "";
    },
    changeInstallEvent: (state, action) => {
      const { name, value } = action.payload;
      (state.defaultState.postback as any)[name] = value;
    },
    addParams: (state) => {
      state.defaultState.params.push({ sub: "", value: "" });
    },
    createParams: (state, action) => {
      const { name, value, id } = action.payload;
      if (id >= 0 && id < state.defaultState.params.length) {
        state.defaultState.params[id as number][name as keyof IParams] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(settingsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(settingsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(settingsThunk.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});

export const {
  changeDefaultState,
  createParams,
  addParams,
  changeInstallEvent,
  changeDefault,
} = settingSlice.actions;
