import { FC, useState } from "react";
import { IconButton, Snackbar } from "@mui/material";

import { TCopyMUIProps } from "./types.ts";
import CopyIcon from "../../../assets/icons/CopyIcon.tsx";

const CopyMUI: FC<TCopyMUIProps> = ({ text, onClick }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    onClick();
    navigator.clipboard.writeText(text).then(() => {
      setOpen(true);
    });
  };

  return (
    <>
      <IconButton onClick={handleClick} color="primary">
        <CopyIcon />
      </IconButton>
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyMUI;
