import styles from "./styles.module.scss";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useAppSelector } from "../../../lib/redux/hooks.ts";
import dayjs from "dayjs";

const DevicesTable = () => {
  const rowsData = useAppSelector((state) => state.pwaDevices.data);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="left">PWA ID</TableCell>
            <TableCell align="left">PWA Name</TableCell>
            <TableCell align="left">Device Token</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData?.devices &&
            rowsData?.devices?.map((row: any, index: number) => {
              return (
                <TableRow
                  key={row.index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className={styles.tableRow}
                >
                  <TableCell key={row.index} component="th" scope="row">
                    {dayjs(row.created_at).format("DD.MM.YYYY HH:mm")}
                  </TableCell>
                  <TableCell key={row.index} align="left">
                    {row.pwa_id}
                  </TableCell>
                  <TableCell key={row.index} align="left">
                    {row.pwa?.name}
                  </TableCell>
                  <TableCell key={row.index} align="left">
                    {row.device_token}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevicesTable;
