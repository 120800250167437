import { FC } from "react";
import styles from "./Select.module.scss";
import { TSelectProps } from "./types.ts";
import useLanguages from "../../../hooks/useLanguages.ts";
import LocalStorageService from "../../../common/services/localStorage.ts";
import { E_Language } from "../../../common/constant/enumConstans.ts";
import { useAppSelector } from "../../../lib/redux/hooks.ts";

const ChangeLanguage: FC<TSelectProps> = ({ Icon }) => {
  const changeLanguages = useLanguages();
  const currLang = LocalStorageService.getLanguage() ?? E_Language.ENG;
  const { data: userData } = useAppSelector((state) => state.auth.userData);

  const color = userData ? "#105B0C" : "#FFFFFF";
  const isAuth = userData ? styles.auth : styles.label;

  const handleChangeLanguage = () => {
    if (currLang == E_Language.ENG) {
      changeLanguages(E_Language.RU);
    } else {
      changeLanguages(E_Language.ENG);
    }
  };

  return (
    <div className={styles.wrapper} onClick={handleChangeLanguage}>
      <p className={isAuth}>
        {Icon && <Icon color={color} />}
        {currLang}
      </p>
    </div>
  );
};
export default ChangeLanguage;
