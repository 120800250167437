import { object, string } from "yup";

const confirmPasswordSchemas = object().shape({
  password: string()
    .required("PasswordIsRequired")
    .matches(
      /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*().\-_=+{};:,<.>]){1,})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>])/,
      "MustContainLeast"
    )
    .min(8, "passwordMustLeastCharacters"),
});

export default confirmPasswordSchemas;
